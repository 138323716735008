import React from "react";
import PreCheckLiveMovie from "../../../general_role/tabs/tab_info/PreCheckLiveMovie";
import {useRecoilValue} from "recoil";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import {timeState} from "../../../../../grobalStates/atoms/timeAtom";
import DisplayRecordMovie from "../../../common/tabs/tab_info/DisplayRecordMovie";
import NoResultBoxLayouts from "../../../common/tabs/tab_info/NoResultBoxLayouts";

type Props = {
    search_result: any;
    state_data: any;
};

const ResultBoxLayouts: React.FC<Props> = ({ search_result, state_data }) => {
    // recoilでLive/Recordedの情報を取得
    const type_record = useRecoilValue(movieTypeState);
    const timeData = useRecoilValue(timeState);

    if (type_record == 'Recorded'){
        if (search_result){
            const searchResultData = Object.values(search_result);
            const resultData = searchResultData[0] as any;
            if (resultData) {
                const detailsData = Object.values(resultData);
                const resultTime = detailsData[1] as string;
                const lat = detailsData[2] as string;
                const long = detailsData[3] as string;
                const alertId = timeData.value;
                if (alertId !== 'Time'){
                    return (
                        <DisplayRecordMovie state_data={state_data}
                                            alertId={alertId}
                                            resultTime={resultTime}
                                            lat={lat}
                                            long={long}
                        />
                    );
                }
            }
        }
        return <NoResultBoxLayouts/>;
        // Live
    } else {
        return (
            <PreCheckLiveMovie state_data={state_data}/>
        );
    }
};

export default ResultBoxLayouts;
