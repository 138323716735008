import React, {useState} from "react";
import TabChanges from "../TabChanges";
import DisplayStaticsMap from "../../common/maps/statics/DisplayStaticsMap";

type Props = {
    object: any;
    user: any;
    allPds: any
};

const StaticsMap: React.FC<Props> = ({ object, user, allPds }) => {
    // Statics地図描画データ
    const [staticsMapData, setStaticsData] = useState(false);

    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <DisplayStaticsMap object={ staticsMapData } />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <TabChanges state_data={ setStaticsData }
                            tab_name={ object }
                            tab_value={ 1 }
                            user={user}
                            all_pds={allPds}></TabChanges>
            </div>
        </div>
    );
};

export default StaticsMap;
