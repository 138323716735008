import React, {useEffect, useState} from "react";
import {GroupSingleSelect} from "../../../../../form/info/group_single_select";
import {PDIdSelect} from "../../../../../form/info/pd_id_select";
import {RadioButton} from "../../../../../form/info/radio_button";
import {StartDaySelect} from "../../../../../form/info/start_day_select";
import {EndDaySelect} from "../../../../../form/info/end_day_select";
import {AlertTypeSelect} from "../../../../../form/info/alert_type_select";
import {useRecoilValue} from "recoil";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import PreCheckResultTimeBox from "../../../common/tabs/tab_info/PreCheckResultTimeBox";
import {timeState} from "../../../../../grobalStates/atoms/timeAtom";

type Props = {
    user: any;
    search_result: any;
    pd_selection: any;
};

//　検索条件をまとめたファイル
const SearchConditions: React.FC<Props> = ({ user, search_result, pd_selection }) => {
    // Map経由で選択値のlabelが設定されていない場合は、一覧から検索する
    const fromMapTime = useRecoilValue(timeState);

    // ラジオボタンの値が変化したときに実行
    const type_record = useRecoilValue(movieTypeState);
    const [disableData, setDisableData] = useState(false);
    useEffect(() => {
        setDisableData(!disableData);
        if (fromMapTime.label === "地図Alert" || fromMapTime.label === "地図Call") {
            setDisableData(false);
        }
    }, [type_record]);

    ////  日付の終了日
    const [endDayData, setEndDayData] = useState(new Date());

    return (
        <div style={{ backgroundColor: '#50687e', paddingBottom: '30px' }}>
            <div>
                <div style={{ display: "flex" }}>
                    {/* Group */}
                    <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Group</label>
                    <GroupSingleSelect user={ user }/>
                </div>
                <div style={{ display: "flex", marginTop: '8px' }}>
                    {/* User */}
                    <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>User</label>
                    <PDIdSelect selection={pd_selection}/>
                </div>
                <div style={{ display: "flex" }}>
                    {/* RadioButton */}
                    <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Type</label>
                    <RadioButton/>
                </div>
                <div style={{ marginTop: '11px', height: '40px'}}>
                    {/* カレンダー */}
                    <div style={{ display: "flex" }}>
                        <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Date</label>
                        <div style={{width: '360px', float: 'right'}}>
                            <div style={{width: '43%', float: 'left'}}>
                                <StartDaySelect disable_type={disableData} />
                            </div>
                            <label style={{marginLeft: '14px', paddingTop: '4px', color: "white", fontSize: "20px"}}>〜</label>
                            <div style={{width: '43%',float: 'right'}}>
                                <EndDaySelect value_info={ setEndDayData } disable_type={disableData} />
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ height: '40px'}}>
                    {/* セレクト */}
                    <div style={{ display: "flex" }}>
                        <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Select</label>
                        <div>
                            <div>
                                <AlertTypeSelect disable_type={disableData} />
                            </div>
                            <PreCheckResultTimeBox search_result={search_result}
                                           end_day={ endDayData }
                                           disable_type={disableData} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchConditions;
