import React from "react";
import clsx from "clsx";
import { createMuiTheme } from "@material-ui/core/styles";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import { Authenticator, View, Image } from '@aws-amplify/ui-react';
import {Amplify} from 'aws-amplify';
import '@aws-amplify/ui-react/styles.css';
import '../pages/login.css';
import awsconfig from '../../awsconfig';
import "@fontsource/poppins";
import LoginHeader from "./LoginHeader";

Amplify.configure(awsconfig);

const drawerWidth = 240;

const theme = createMuiTheme({
    typography: {
        fontFamily: [
            "Poppins",
            "Noto Sans JP",
            "Lato",
            "游ゴシック Medium",
            "游ゴシック体",
            "Yu Gothic Medium",
            "YuGothic",
            "ヒラギノ角ゴ ProN",
            "Hiragino Kaku Gothic ProN",
            "メイリオ",
            "Meiryo",
            "ＭＳ Ｐゴシック",
            "MS PGothic",
            "sans-serif",
        ].join(","),
    },
    palette: {
        primary: { main: '#50697e' } //colors.common['black']
    },
});

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: "flex",
            fontFamily: "Poppins",
            fontWeight: 600,
        },
        toolbar: {
            paddingLeft: "56px",
            paddingRight: "33px",
            height: "120px",
        },
        toolbarIcon: {
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            padding: "0 8px",
            ...theme.mixins.toolbar,
        },
        appBar: {
            zIndex: theme.zIndex.drawer + 1,
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
        },
        appBarShift: {
            transition: theme.transitions.create(["width", "margin"], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        menuButton: {
            marginRight: 36,
        },
        menuButtonHidden: {
            display: "none",
        },
        title: {
            flexGrow: 1,
            fontWeight: 600,
            fontSize: "48px",
        },
        pageTitle: {
            margin: 0,
        },
        drawerPaper: {
            position: "relative",
            whiteSpace: "nowrap",
            width: drawerWidth,
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
        drawerPaperClose: {
            overflowX: "hidden",
            transition: theme.transitions.create("width", {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
            }),
            width: theme.spacing(7),
            [theme.breakpoints.up("sm")]: {
                width: theme.spacing(9),
            },
        },
        appBarSpacer: {
            ...theme.mixins.toolbar,
            height: "120px",
        },
        content: {
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            backgroundColor: "#5f7d95",
        },
        container: {
            height: "calc(100vh - 120px)",
            padding: 0,
        },
        paper: {
            padding: theme.spacing(2),
            display: "flex",
            overflow: "auto",
            flexDirection: "column",
        },
        link: {
            textDecoration: "none",
            color: theme.palette.text.secondary,
        },
    })
);

const authComponents = {
    Header() {
        return (
            <View textAlign="center">
                <Image style={{color: 'white'}}
                       alt="logo"
                       src="/static/logo_h80.png"
                />
                <h1 style={{color: 'white'}}>Pyrenee Global Connect</h1>
            </View>
        );
    },
    SignIn: {
        Footer() {
            return <div></div>;
        },
    },
};

export interface GenericTemplateProps {
    children: React.ReactNode;
    title: string;
    login_user: any;
}

const GenericTemplate: React.FC<GenericTemplateProps> = ({
                                                             children,
                                                             title,
                                                             login_user,
                                                         }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };

    return (
        <Authenticator hideSignUp={true} components={authComponents}>
            {({ signOut, user }) => (
                <ThemeProvider theme={theme}>
                    <div className={classes.root}>
                        <CssBaseline />
                        <AppBar
                            position="absolute"
                            className={clsx(classes.appBar, open && classes.appBarShift)}
                        >
                            <Toolbar className={classes.toolbar}>
                                <Typography
                                    component="h1"
                                    variant="h5"
                                    color="inherit"
                                    align="left"
                                    noWrap
                                    className={classes.title}
                                >
                                    Pyrenee Global Connect
                                </Typography>
                                <LoginHeader loginId={user.username} login_user={login_user}></LoginHeader>
                                <div onClick={signOut} style={{ float: 'right', paddingTop: "10px" }}>
                                    <img src={`${process.env.PUBLIC_URL}/static/logout.png`} alt="logout" style={{width: "56px", height: "61px"}}/>
                                </div>
                            </Toolbar>
                        </AppBar>
                        <main className={classes.content}>
                            <div className={classes.appBarSpacer} />
                            <Container maxWidth={false} className={classes.container}>
                                <Typography
                                    component="h2"
                                    variant="h5"
                                    color="inherit"
                                    noWrap
                                    className={classes.pageTitle}
                                >
                                    {title}
                                </Typography>
                                {children}
                            </Container>
                        </main>
                    </div>
                </ThemeProvider>
            )}
        </Authenticator>
    );
};

export default GenericTemplate;
