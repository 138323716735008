import React, { Fragment } from 'react';
import Select from 'react-select';
import {useRecoilState} from "recoil";
import {groupListState} from "../../grobalStates/atoms/groupListAtom";
import {SelectStyles} from "../SelectStyles";

type Props = {
    selection: any;
};

export const AllSelect: React.FC<Props> = ( {selection}) => {
    // recoilでグループの情報を取得
    const [group, setGroup] = useRecoilState(groupListState);

    // 選択が変更されたらグループのrecoilのAtomを変更する
    const onChange = (target: any) => {
        setGroup(target)
    };

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                onChange={onChange}
                className="basic-single"
                classNamePrefix="group_select"
                defaultValue={group}
                isDisabled={false}
                name="group_select"
                options={selection}
            />
        </Fragment>
    );
}
