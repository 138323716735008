import React, {Fragment} from 'react';
import Select from 'react-select';
import {useRecoilState, useSetRecoilState} from "recoil";
import {groupListState} from "../../../grobalStates/atoms/groupListAtom";
import {pdsListState} from "../../../grobalStates/atoms/pdsListAtom";
import {SelectStyles} from "../../SelectStyles";
import {timeState} from "../../../grobalStates/atoms/timeAtom";

type SingleProps = {
    group: any;
};

export const GroupSingleSelect: React.FC<SingleProps> = ({ group }) => {
    const [defaultGroup, setGroup] = useRecoilState(groupListState);
    const setPd = useSetRecoilState(pdsListState);
    const setTime = useSetRecoilState(timeState);

    const onChange = (target: any) => {
        // グループ変更時に紐ずくUserとTimeの選択を初期化
        // 例: 中国(PD中国)→日本(PD日本)　中国グループで選択中の中国PDは日本のグループに含まれないので、変更必要
        // 例: All(PD中国)→日本　Allで選択中の中国PDは日本のグループに含まれないので、変更必要
        // 例: 日本→All　選択中の日本PDはAllのグループに含まれるので、変更不必要
        const ChangingGroupObject = Object.values(target);
        const ChangingGroupLabel = ChangingGroupObject[1];
        if (ChangingGroupLabel !== 'All'){
            setTime({value: 'Time', label: 'Time'});
            setPd({value: 'All', label: 'All'});
        }
        setGroup(target)
    };

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                onChange={onChange}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={defaultGroup}
                isDisabled={false}
                name="group"
                options={group}
            />
        </Fragment>
    );
}
