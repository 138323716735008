// TODO: 選択したグループから国特定ができるAPIが完成した別ファイルを参考に書き換える
import React, {useEffect, useState} from "react";
import axios from 'axios';

type Props = {
    lat: number;
    lng: number;
};

const IdentifyingRecordedAddress: React.FC<Props> = ({ lat, lng}) => {
    const [geoLoaded, setGeoLoaded] = useState(false);
    const [displayAddress, setAddressInfo] = useState('');

    const baseURL = "https://maps.googleapis.com/maps/api/geocode/json?";
    const api_key = 'AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic';

    // ジオコーディングAPI
    const initGeoExec = async (lat: number, lng: number) => {
        try {
            const lat_lng = 'latlng=' + lat + ',' + lng as string;
            const google_key = '&key=' + api_key as string;
            const make_url = baseURL + lat_lng + google_key + '&language=ja' as string;
            const axios_response = await axios.get(make_url);
            const response = Object.values(axios_response.data.results);
            if (response) {
                const geo_data = response[0] as object;
                const object_data = Object.values(geo_data).map(data => data);
                const address_results = object_data[0] as any;
                const elements = [];
                for (let i = 0; i < address_results.length; i++) {
                    const detail_address = address_results[i] as unknown as any;
                    const type = detail_address.types;
                    if (type.includes('administrative_area_level_1') ||
                        type.includes('locality') ||
                        type.includes('sublocality_level_1') ||
                        type.includes('sublocality_level_2') ||
                        type.includes('sublocality_level_3')
                    ) {
                        elements.push(detail_address.short_name);
                    }
                }
                elements.reverse();
                const full_address = elements.join('');
                setAddressInfo(full_address);
                setGeoLoaded(true);
            } else {
                window.alert("No results found");
            }
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        initGeoExec(lat, lng);
    }, [lat, lng]);

    return geoLoaded ? <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{displayAddress}</label> : <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>identifying Address...</label>
}

export default IdentifyingRecordedAddress;
