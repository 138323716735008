import React, { Fragment, useEffect} from 'react';
import Select from 'react-select';
import {useRecoilState, useSetRecoilState} from "recoil";
import {notificationKindState} from "../../grobalStates/atoms/notificationKindAtom";
import {SelectStyles} from "../SelectStyles";
import {timeState} from "../../grobalStates/atoms/timeAtom";

type Props = {
    disable_type: any;
};

export const AlertTypeSelect: React.FC<Props> = ( { disable_type }) => {
    // recoilからアラートタイプを継承
    const [defaultNotificationKind, setNotificationKind] = useRecoilState(notificationKindState);
    const setTime = useSetRecoilState(timeState);
    const onChange = (target: any) => {
        setTime({value: 'Time', label: 'Time'});
        setNotificationKind(target)
    };

    const selectable = [
        {value: 1, label: 'Alert'},
        {value: 2, label: 'Calls'},
    ]

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                onChange={onChange}
                className="alert_type_select"
                classNamePrefix="alert_type_select"
                defaultValue={defaultNotificationKind}
                isDisabled={disable_type}
                name="alert_type_select"
                options={selectable}
            />
        </Fragment>
    );
}
