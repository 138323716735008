import React, {Fragment, useState} from 'react';
import Select from 'react-select';
import {useRecoilState} from "recoil";
import {timeState} from "../../grobalStates/atoms/timeAtom";
import {SelectStyles} from "../SelectStyles";
import {FromMapTimeSelect} from "./fromMapTimeSelect";

type Props = {
    disable_type: any;
    option: any;
    resultInfo: any;
    search_result: any;
};

export const TimeSelect: React.FC<Props> = ( { disable_type, option, resultInfo, search_result }) => {
    // onChangeで選択されたvalueの情報からresultInfoを選択しsearch_resultとして返却
    const [defaultTime, setTimeData] = useRecoilState(timeState);

    // Map経由で選択値のlabelが設定されていない場合は、一覧から検索する
    const [fromMapData, setFromMapDate] = useState(false);
    if (defaultTime.label === "地図Alert" || defaultTime.label === "地図Call") {
        const time = option.find((data: any) => data.value == defaultTime.value);
        if (time){
            if (resultInfo){
                const eData = Object.values(time);
                const filterId = eData[0];
                const target = resultInfo.filter((data: any) => data.id === filterId);
                search_result(target);
            }
            setTimeData(time);
            setFromMapDate(true);
        }
    }

    const handleChange = (e:any) => {
        // onChangeで選択されたvalueをatomで保持(動画検索用)
        setTimeData(e)
        if (resultInfo){
            const eData = Object.values(e);
            const filterId = eData[0];
            const target = resultInfo.filter((data: any) => data.id === filterId);
            search_result(target);
        }
    };

    if (fromMapData){
        return (
            <FromMapTimeSelect disable_type={disable_type} option={option} resultInfo={resultInfo} search_result={search_result}/>
        );
    } else {
        return (
            <Fragment>
                <Select
                    styles={SelectStyles}
                    onChange={handleChange}
                    className="time_select"
                    classNamePrefix="time_select"
                    defaultValue={defaultTime}
                    isDisabled={disable_type}
                    name="time_select"
                    options={option}
                />
            </Fragment>
        );
    }
}
