import React, {useEffect, useState} from "react";
import {PdIdsInput, PdIdsPdType, UsersInput, UsersUserType} from "../../../../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getPdIds, getUsers} from "../../../../../graphql/queries";
import DisplayStaticsTab from "./DisplayStaticsTab";

// selectable(全グループのリスト)
// group_value(ステートを変更する際に利用)
// map_result(mapの描画用)
// group_data(選択されたグループのバリュー)
type Props = {
    selectable: any;
    map_result: any;
    user_group_id: number | null;
};

const GetPds: React.FC<Props> = ({selectable, map_result, user_group_id}) => {
    const [detailLoaded, setDetailLoaded] = useState(false);
    const [detailData, setDetailData] = useState<PdIdsPdType | []>([]);
    const [usersData, setUsersData] = useState<UsersUserType | []>([]);

    const initUserGroupExec = async (user_group_id: number | null) => {
        let initUserGroupIdInput: PdIdsInput & UsersInput | null = null;
        if (user_group_id) initUserGroupIdInput = { userGroupId: user_group_id };
        try {
            const pdsResponse = await API.graphql(graphqlOperation(getPdIds, { input: initUserGroupIdInput }));
            const usersResponse = await API.graphql(graphqlOperation(getUsers, { input: initUserGroupIdInput }));
            const userList: any = {};
            // @ts-ignore
            usersResponse.data.getUsers.data.users.forEach((user) => { userList[user.id] = user });
            // @ts-ignore
            setDetailData(pdsResponse.data.getPdIds.data.pds);
            setUsersData(userList);
            setDetailLoaded(true);
        } catch (e) {
            console.log(e);
        }
    };

    // グループが変化したときに実行
    useEffect(() => {
        initUserGroupExec(user_group_id);
    }, [user_group_id]);


    if (detailLoaded) {
        const pds = [];
        pds.push({ value: 'All', label: 'All' })
        // Pds
        detailData.forEach((data) => {
            pds.push(
                { value: data.id, label: `${data.identifier}(${usersData[data.userId]?.name || "-"})` }
            );
        });

        return(
            // selectable(全グループのリスト)
            // map_result(mapの描画用)
            // pds(全PDのリスト)
            <DisplayStaticsTab selectable={selectable} map_result={map_result} pds={pds}></DisplayStaticsTab>
        );
    } else {
        return (
            <div style={{ color: 'white', height: "300px" }}>
                <p>loading...</p>
            </div>
        );
    }
};

export default GetPds;
