import React, {useState} from "react";
import ActivesMap from "../general_role/maps/ActivesMap";
import StaticsMap from "../general_role/maps/StaticsMap";
import InfoMap from "../general_role/maps/InfoMap";

type Props = {
    userId: number;
    userName: string;
    allPds: any;
    userList: any;
    pdUserMap: any;
};

const SetTabAndTab: React.FC<Props> = ({userId, userName, allPds, userList, pdUserMap}) => {

    const user = {value: userId, label: userName};
    // タブで地図の描画を変更(初期値はアクティブのMap)
    const [tabNameData, setTabName] = useState('actives')
    const tabName = tabNameData as unknown as string
    // タブがactivesの時
    if (tabName == 'actives') {
        return(
            <ActivesMap object={setTabName} user={user} allPds={allPds} userList={userList} pdUserMap={pdUserMap}></ActivesMap>
        );
        // タブがstaticsの時
    } else if (tabName == 'statics') {
        return (
            <StaticsMap object={setTabName} user={user} allPds={allPds}></StaticsMap>
        );
        // タブがinfoの時
    } else {
        return (
            <InfoMap object={setTabName} user={user} allPds={allPds}></InfoMap>
        );
    }
};

export default SetTabAndTab;
