/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getMyData = /* GraphQL */ `
  query MyQuery {
    getMyData {
      statusCode
      data {
        id
        name
        account
        userGroup {
          id
          name
          role
          country {
            id
            name
            region {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const users = /* GraphQL */ `
  query Users {
    users {
      id
      account
      name
      userGroupId
      pyreneeDriveId
    }
  }
`;

export const getUserGroups = /* GraphQL */ `
  query MyQuery {
    getUserGroups {
      statusCode
      data {
        userGroups {
          id
          name
          countryId
          role
        }
      }
    }
  }
`;

export const getPdSummary = /* GraphQL */ `
  query MyQuery($input: PdSummaryInput) {
    getPdSummary (input: $input) {
      statusCode
      data {
        countries {
          active
          name
          inactive
          total
        }
        regions {
          active
          inactive
          name
          total
        }
        total {
          active
          inactive
          total
        }
        actives {
          id
          iconKind
          lat
          lng
          direction
        }
        inactives {
          id
          iconKind
          lat
          lng
          direction
        }
      }
    }
  }
`;

export const getPdIds= /* GraphQL */ `
  query MyQuery($input: PdIdsInput) {
    getPdIds (input: $input) {
      statusCode
      data {
        pds {
          id
          identifier
          userId
        }
      }
    }
  }
`;

export const getUsers= /* GraphQL */ `
  query MyQuery($input: UsersInput) {
    getUsers (input: $input) {
      statusCode
      data {
        users {
          id
          name
          userGroupId
        }
      }
    }
  }
`;

export const getStreamingUrl= /* GraphQL */ `
  query MyQuery($input: StreamingUrlInput!) {
    getStreamingUrl (input: $input) {
      statusCode
      data {
        url
      }
    }
  }
`;

export const getStreamingInfo= /* GraphQL */ `
  query MyQuery($input: StreamingInfoInput!) {
    getStreamingInfo (input: $input) {
      statusCode
      data {
        lat
        long
        speed
        time
        iconKind
        direction
      }
    }
  }
`;

export const requestStreaming= /* GraphQL */ `
  query MyQuery($input: RequestStreamingInput!) {
    requestStreaming (input: $input) {
      statusCode
    }
  }
`;

export const getPdAlerts= /* GraphQL */ `
  query MyQuery($input: PdAlertsInput!) {
    getPdAlerts (input: $input) {
      statusCode
      data {
        alerts {
          id
          time
          lat
          long
        }
      }
    }
  }
`;

export const getPdAlertDetail= /* GraphQL */ `
  query MyQuery($input: PdAlertDetailInput!) {
    getPdAlertDetail (input: $input) {
      statusCode
      data {
        speed
        url
      }
    }
  }
`;

export const getStatisticsLatlongLists= /* GraphQL */ `
  query MyQuery($input: StatisticsLatlongListsInput) {
    getStatisticsLatlongLists (input: $input) {
      statusCode
      data {
        latlongLists {
          lat
          lng
        }
      }
    }
  }
`;

export const getStatisticsAlerts= /* GraphQL */ `
  query MyQuery($input: StatisticsAlertsInput) {
    getStatisticsAlerts (input: $input) {
      statusCode
      data {
        alerts {
          id
          lat
          long
          pdId
        }
      }
    }
  }
`;

export const getStatisticsTab= /* GraphQL */ `
  query MyQuery($input: StatisticsTabInput) {
    getStatisticsTab (input: $input) {
      statusCode
      data {
        travels
        alerts
        calls
        graphs {
          travelsGraph {
            labels
            values
          }
          alertsGraph {
            labels
            values
          }
          callsGraph {
            labels
            values
          }
        }
      }
    }
  }
`;
