import React, { useState } from "react";
import { GoogleMap, LoadScript, Marker, InfoBox } from "@react-google-maps/api";
import { MapStyles } from "../../../MapStyles";
import { carIconSvgPath, carIconSvgRotateOffset, strokeColor, activeFillColor, inactiveFillColor } from "../../../CarIconStyles";
import {useSetRecoilState} from "recoil";
import { viaTypeState } from "../../../../../grobalStates/atoms/viaTypeAtom";
import { pdsListState } from "../../../../../grobalStates/atoms/pdsListAtom";

const infoboxStyle: React.CSSProperties = {
    position: "relative",
    width: "100px",
    height: "20px",
    background: "#FFFFFF",
    padding: "2px",
    textAlign: "center",
    border: "1px solid #000000",
    color: "#000000",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "5px",
};

const infoboxAfterStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "38%",
    left: "94%",
    borderColor: "rgba(255, 255, 255, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    borderTopColor: "#FFFFFF",
}

const infoboxBeforeStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "38%",
    left: "94%",
    borderColor: "rgba(0, 0, 0, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    marginTop: "2px",
    borderTopColor: "#000000",
}

const infoboxLargeStyle: React.CSSProperties = {
    position: "relative",
    width: "100px",
    height: "34px",
    background: "#FFFFFF",
    padding: "2px",
    textAlign: "center",
    border: "1px solid #000000",
    color: "#000000",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "5px",
};

const infoboxLargeAfterStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "52%",
    left: "93%",
    borderColor: "rgba(255, 255, 255, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    borderTopColor: "#FFFFFF",
}

const infoboxLargeBeforeStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "52%",
    left: "93%",
    borderColor: "rgba(0, 0, 0, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    marginTop: "2px",
    borderTopColor: "#000000",
}



type Props = {
    actives: any;
    inactives: any;
    user_list?: any;
    pd_user_map?: any;
};

const containerStyle = {
    height: "calc(100vh - 120px)",
    width: "100%",
};

const center = {
    lat: 35.709984139,
    lng: 139.810703,
};

const options = {
    styles: MapStyles,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "hybrid"],
    },
    minZoom: 2,
};


const SetActivesMap: React.FC<Props> = ({ actives, inactives, user_list, pd_user_map }) => {

    const setViaChange = useSetRecoilState(viaTypeState);
    const setPd = useSetRecoilState(pdsListState);

    const setInfoTab = (pdId: number, _e: any) => {
        setPd({ value: String(pdId), label: "" })
        setViaChange('byMap')
    }

    const [currentZoom, setCurrentZoom] = useState(5);//default

    function handleZoomChanged(this: any) {
        setCurrentZoom(this.getZoom());
    }

    const display_active = actives.map((active: any) => {
        const userId = pd_user_map && pd_user_map[active.id];
        const userName = (user_list && user_list[userId]?.name) || "-" ;
        const userNameLength = userName.length;
        if (currentZoom < 10) {
            return (
                <div key={active.id} >
                    <Marker
                        onClick={e => setInfoTab(active.id, e)}
                        position={active}
                        icon={{
                            path: carIconSvgPath,
                            // scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(27, 21),
                            fillColor: activeFillColor(active.iconKind),
                            fillOpacity: 1,
                            strokeColor: strokeColor(active.iconKind),
                            strokeWeight: 2,
                            scale: 0.45,
                            rotation: active.direction + carIconSvgRotateOffset,
                        }}
                    />
                </div>
            );
        } else {
            // 文字数が多く２段になる場合
            if (userNameLength >= 15) {
                return (
                    <div key={active.id}>
                        <Marker
                            onClick={e => setInfoTab(active.id, e)}
                            position={active}
                            icon={{
                                path: carIconSvgPath,
                                // scaledSize: new google.maps.Size(54, 54),
                                anchor: new google.maps.Point(27, 21),
                                fillColor: activeFillColor(active.iconKind),
                                fillOpacity: 1,
                                strokeColor: strokeColor(active.iconKind),
                                strokeWeight: 2,
                                scale: 0.45,
                                rotation: active.direction + carIconSvgRotateOffset,
                            }}
                        />
                        <InfoBox position={active} options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(-90, -55) }} >
                            <div style={{ width: "100px", height: "60px" }}>
                                <div style={infoboxLargeBeforeStyle}></div>
                                <div onClick={e => setInfoTab(active.id, e)} style={infoboxLargeStyle} >
                                    {userName}
                                </div>
                                <div style={infoboxLargeAfterStyle}></div>
                            </div>
                        </InfoBox>
                    </div>
                );
            } else {
                return (
                    <div key={active.id}>
                        <Marker
                            onClick={e => setInfoTab(active.id, e)}
                            position={active}
                            icon={{
                                path: carIconSvgPath,
                                // scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(27, 21),
                                fillColor: activeFillColor(active.iconKind),
                                fillOpacity: 1,
                                strokeColor: strokeColor(active.iconKind),
                                strokeWeight: 2,
                                scale: 0.45,
                                rotation: active.direction + carIconSvgRotateOffset,
                            }}
                        />
                        <InfoBox position={active} options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(-90, -45) }} >
                            <div style={{ width: "100px", height: "45px" }}>
                                <div style={infoboxBeforeStyle}></div>
                                <div onClick={e => setInfoTab(active.id, e)} style={infoboxStyle} >
                                    {userName}
                                </div>
                                <div style={infoboxAfterStyle}></div>
                            </div>
                        </InfoBox>
                    </div>
                );
            }
        }
    })

    const display_inactive = inactives.map((inactive: any) => {
        const userId = pd_user_map && pd_user_map[inactive.id];
        const userName = (user_list && user_list[userId]?.name) || "-" ;
        const userNameLength = userName.length;
        if (currentZoom < 10) {
            return (
                <div key={inactive.id}>
                    <Marker
                        onClick={e => setInfoTab(inactive.id, e)}
                        position={inactive}
                        icon={{
                            path: carIconSvgPath,
                            // scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(27, 21),
                            fillColor: inactiveFillColor,
                            fillOpacity: 1,
                            strokeColor: strokeColor(inactive.iconKind),
                            strokeWeight: 2,
                            scale: 0.45,
                            rotation: inactive.direction + carIconSvgRotateOffset,
                        }}
                    />
                </div>
            );
        } else {
            // 文字数が多く２段になる場合
            if (userNameLength >= 15) {
                return (
                    <div key={inactive.id}>
                        <Marker
                            onClick={e => setInfoTab(inactive.id, e)}
                            position={inactive}
                            icon={{
                                path: carIconSvgPath,
                                // scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(27, 21),
                                fillColor: inactiveFillColor,
                                fillOpacity: 1,
                                strokeColor: strokeColor(inactive.iconKind),
                                strokeWeight: 2,
                                scale: 0.45,
                                rotation: inactive.direction + carIconSvgRotateOffset,
                            }}
                        />
                        <InfoBox position={inactive} options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(-90, -55) }} >
                            <div style={{ width: "100px", height: "60px" }}>
                                <div style={infoboxLargeBeforeStyle}></div>
                                <div onClick={e => setInfoTab(inactive.id, e)} style={infoboxLargeStyle} >
                                    {userName}
                                </div>
                                <div style={infoboxLargeAfterStyle}></div>
                            </div>
                        </InfoBox>
                    </div>
                );
            } else {
                return (
                    <div key={inactive.id}>
                        <Marker
                            onClick={e => setInfoTab(inactive.id, e)}
                            position={inactive}
                            icon={{
                                path: carIconSvgPath,
                                // scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(27, 21),
                                fillColor: inactiveFillColor,
                                fillOpacity: 1,
                                strokeColor: strokeColor(inactive.iconKind),
                                strokeWeight: 2,
                                scale: 0.45,
                                rotation: inactive.direction + carIconSvgRotateOffset,
                            }}
                        />
                        <InfoBox position={inactive} options={{ disableAutoPan: true, pixelOffset: new google.maps.Size(-90, -45) }} >
                            <div style={{ width: "100px", height: "45px" }}>
                                <div style={infoboxBeforeStyle}></div>
                                <div onClick={e => setInfoTab(inactive.id, e)} style={infoboxStyle} >
                                    {userName}
                                </div>
                                <div style={infoboxAfterStyle}></div>
                            </div>
                        </InfoBox>
                    </div>
                );
            }
        }
    })

    return (
        <div title="MAP">
            <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5} options={options} onZoomChanged={handleZoomChanged}>
                    { display_active }
                    { display_inactive }
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default SetActivesMap;
