import React from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { MapStyles } from "../../../MapStyles";
import { carIconSvgPath, carIconSvgRotateOffset, selectedFillColor, strokeColor } from "../../../CarIconStyles";

type Props = {
    currentPosition: any;
};

const containerStyle = {
    height: "calc(100vh - 120px)",
    width: "100%",
};

const options = {
    styles: MapStyles,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "hybrid"],
    },
    minZoom: 2,
};

const SetInfoMap: React.FC<Props> = ({ currentPosition }) => {
    const data = Object.values(currentPosition)
    const lat = data[0] as number;
    const lng = data[1] as number;
    const position = {lat: lat, lng: lng};
    const iconType = data[2] as string;
    if (iconType == 'live'){
        const iconKind = data[3] as number;
        const direction = data[4] as number;
        return (
            <div title="MAP">
                <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                    <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={8} options={options}>
                        <Marker
                            position={position}
                            icon={{
                                path: carIconSvgPath,
                                // scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(27, 21),
                                fillColor: selectedFillColor,
                                fillOpacity: 1,
                                strokeColor: strokeColor(iconKind),
                                strokeWeight: 2,
                                scale: 0.45,
                                rotation: direction + carIconSvgRotateOffset,
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    } else if (iconType == 'alert'){
        return (
            <div title="MAP">
                <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                    <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={8} options={options}>
                        <Marker
                            position={position}
                            icon={{
                                url: "/static/mock_images/z16-alert.png",
                                scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(14, 16)
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    } else if  (iconType == 'call'){
        return (
            <div title="MAP">
                <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                    <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={8} options={options}>
                        <Marker
                            position={position}
                            icon={{
                                url: "/static/mock_images/z16-call.png",
                                scaledSize: new google.maps.Size(28, 28),
                                anchor: new google.maps.Point(14, 16)
                            }}
                        />
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    } else {
        return (
            <div title="MAP">
                <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                    <GoogleMap mapContainerStyle={containerStyle} center={position} zoom={8} options={options}>
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    }
};

export default SetInfoMap;
