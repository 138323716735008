import styled from "styled-components";

export const StyledRadioButton = styled.input`
  opacity: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  background: #ffffff;
  position: relative;
  top: 5px;
  ${(props) =>
    props.checked &&
    `
    &:checked {
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 14px;
        height: 14px;
        margin: 5px;
        background: #45ff00;
      }
    }
  `}
`;
