import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./components/pages/HomePage";

const App: React.FC = () => {
    return (
        <div>
            <Router basename="/">
                <Switch>
                    <Route path="/" component={HomePage} exact />
                </Switch>
            </Router>
        </div>
    );
};

export default App;
