import React, { useEffect, useState } from 'react';
import { usePrevious } from "react-use";
import { API, graphqlOperation } from 'aws-amplify';
import { getPdSummary} from "../../../../../graphql/queries";
import { pdSummaryInput, PdSummaryDataType} from '../../../../../API';
type Props = {
    user_group_id: number | null;
    state_data: any;
};

// グループユーザーが一般のpdCurrentInfo情報を取得するファイル
export const GetPdSummaryInfo: React.FC<Props> = ( { user_group_id, state_data } ) => {
    // 読み込み情報
    const [displayReady, setDisplayReady] = useState(false);
    // 取得情報
    const [pdSummary, setPdSummary] = useState<PdSummaryDataType>();

    // 無限ループ対策で取得した情報が更新された際に古いデータと比較する目的
    const old_data = usePrevious(pdSummary);

    // 初回実行時
    const [firstTime, setFirstTime] = useState(true);

    // ユーザ切り替え時
    const [changeGroup, setChangeGroup] = useState(true);

    // 10sで送信するため
    const [each10Time, set10Time] = useState(true);


    const initUserGroupExec = async (user_group_id: number | null) => {
        let initUserGroupIdInput: pdSummaryInput | null = null;
        if (user_group_id) initUserGroupIdInput = { userGroupId: user_group_id };
        try {
            const response = await API.graphql(graphqlOperation(getPdSummary, { input: initUserGroupIdInput }));
            // @ts-ignore
            setPdSummary(response.data.getPdSummary.data);
            setDisplayReady(true);
        } catch (e) {
            console.log(e);
        }
    };

    // グループが変化したときに実行
    useEffect(() => {
        initUserGroupExec(user_group_id);
        setChangeGroup(true)
    }, [user_group_id]);

    // setInterval用のカウント10秒経過でuseEffectを走らせる
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('10秒経過ActiveMap再リクエストを投げます');
            initUserGroupExec(user_group_id);
            set10Time(true)
        }, 10000);
        return () => clearInterval(interval);
    }, [user_group_id]);

    if (displayReady && pdSummary) {
        // タブ描画用の記述
        let display_global = []
        let display_countries = []
        let display_regions = []

        // Global
        display_global.push(
            <table style={{ tableLayout: 'fixed', width: '100%', textAlign: 'center' }}>
                <tbody>
                    <tr>
                        <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_total.png`} width="78px" height="33px"/></td>
                        <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_active.png`} width="78px" height="33px"/></td>
                        <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_inactive.png`} width="78px" height="33px"/></td>
                    </tr>
                    <tr>
                        <td style={{ paddingTop: "5px" }} >Total</td>
                        <td style={{ paddingTop: "5px", color: '#ffa2cd' }}>Active</td>
                        <td style={{ paddingTop: "5px", color: '#00ffff' }}>Inactive</td>
                    </tr>
                    <tr>
                        <td style={{ paddingTop: "5px" }}>{ pdSummary.total.total }</td>
                        <td style={{ paddingTop: "5px", color: '#ffa2cd' }}>{ pdSummary.total.active }</td>
                        <td style={{ paddingTop: "5px", color: '#00ffff' }}>{ pdSummary.total.inactive }</td>
                    </tr>
                </tbody>
            </table>
        )

        // Country
        display_countries = pdSummary.countries.map((country) => {
            return  <tr>
                        <td style={{ width: '40%', textAlign: 'left' }}>{ country.name }</td>
                        <td>{ country.total }</td>
                        <td style={{ color: '#ffa2cd' }}>{ country.active }</td>
                        <td style={{ color: '#00ffff' }}>{ country.inactive }</td>
                    </tr>
        });

        // Region
        display_regions =  pdSummary.regions.map((region) => {
            return  <tr>
                        <td style={{ width: '40%', textAlign: 'left' }}>{ region.name }</td>
                        <td>{ region.total }</td>
                        <td style={{ color: '#ffa2cd' }}>{ region.active }</td>
                        <td style={{ color: '#00ffff' }}>{ region.inactive }</td>
                    </tr>
        });

        // HomePage.tsxのステートに入る記述
        // 形式[ [{id: xxx, lat: xxx, long: xxx},{id: xxx, lat: xxx, long: xxx}], [{id: xxx, lat: xxx, long: xxx},{id: xxx, lat: xxx, long: xxx}] ]

        if (firstTime) {
            // 初回実行時
            const return_to_map = [];
            return_to_map.push(pdSummary.actives, pdSummary.inactives);
            state_data(return_to_map);
            setFirstTime(false);
        } else if (changeGroup && (old_data !== pdSummary)) {
            // グループが設定された時
            const return_to_map = [];
            return_to_map.push(pdSummary.actives, pdSummary.inactives);
            state_data(return_to_map);
            setChangeGroup(false);
        } else if (each10Time && (old_data !== pdSummary)){
            // 10秒経過時、古いデータと取得データに変化のあった時
            const return_to_map = [];
            return_to_map.push(pdSummary.actives, pdSummary.inactives);
            state_data(return_to_map);
            set10Time(false);
        }

        return (
            <div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Global</h2>
                    <div>{display_global}</div>
                </div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Country</h2>
                    <table style={{ tableLayout: 'fixed', width: '100%', padding: "0 20px", textAlign: 'right', fontSize: "16px", fontWeight: "500" }}>
                        <tbody>
                            {display_countries}
                        </tbody>
                    </table>
                </div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Region</h2>
                    <table style={{ tableLayout: 'fixed', width: '100%', padding: "0 20px", textAlign: 'right', fontSize: "16px", fontWeight: "500" }}>
                        <tbody>
                            {display_regions}
                        </tbody>
                    </table>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Global</h2>
                    <div>
                        <table style={{ tableLayout: 'fixed', width: '100%', textAlign: 'center' }}>
                            <tbody>
                                <tr>
                                    <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_total.png`} width="78px" height="33px"/></td>
                                    <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_active.png`} width="78px" height="33px"/></td>
                                    <td style={{ paddingTop: "10px" }}><img src={`${process.env.PUBLIC_URL}/static/mock_images/car_inactive.png`} width="78px" height="33px"/></td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: "5px" }} >Total</td>
                                    <td style={{ paddingTop: "5px", color: '#ffa2cd' }}>Active</td>
                                    <td style={{ paddingTop: "5px", color: '#00ffff' }}>Inactive</td>
                                </tr>
                                <tr>
                                    <td style={{ paddingTop: "5px" }}>loading...</td>
                                    <td style={{ paddingTop: "5px", color: '#ffa2cd' }}>loading...</td>
                                    <td style={{ paddingTop: "5px", color: '#00ffff' }}>loading...</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Country</h2>
                    <table style={{ tableLayout: 'fixed', width: '100%', padding: "0 20px", textAlign: 'right', fontSize: "16px", fontWeight: "500" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '40%', textAlign: 'left' }}>loading...</td>
                                <td>loading...</td>
                                <td style={{ color: '#ffa2cd' }}>loading...</td>
                                <td style={{ color: '#00ffff' }}>loading...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div style={{ color: 'white' }}>
                    <h2 style={{ fontSize: "20px", paddingLeft: '10px', paddingBottom: '2px', borderBottom: "1px solid white" }}>Region</h2>
                    <table style={{ tableLayout: 'fixed', width: '100%', padding: "0 20px", textAlign: 'right', fontSize: "16px", fontWeight: "500" }}>
                        <tbody>
                            <tr>
                                <td style={{ width: '40%', textAlign: 'left' }}>loading...</td>
                                <td>loading...</td>
                                <td style={{ color: '#ffa2cd' }}>loading...</td>
                                <td style={{ color: '#00ffff' }}>loading...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
};
