const carIconSvgPath = "M42.679,5.027a1.53,1.53,0,0,1,.315,1.7L25.687,45.674a1.53,1.53,0,0,1-2.805-.022L16.637,31.071,2.052,24.823a1.53,1.53,0,0,1-.018-2.8L40.976,4.713a1.53,1.53,0,0,1,1.7.315Z";

const carIconSvgRotateOffset = -45; // 上記SVGが真上基準になるための回転オフセット

function strokeColor(iconKind: number): string {
    let activeFillColor = "#00B3B5";
    if (iconKind === 1) {
        activeFillColor = "#00B3B5"; // toC枠線カラー
    } else if (iconKind === 2) {
        activeFillColor = "#2974EF"; // toB枠線カラー
    }
    return activeFillColor
}

function activeFillColor(iconKind: number): string {
    let activeFillColor = "#6DD7D9";
    if (iconKind === 1) {
        activeFillColor = "#6DD7D9"; // アクティブtoC塗りつぶしカラー
    } else if (iconKind === 2) {
        activeFillColor = "#7DAFFF"; // アクティブtoB塗りつぶしカラー
    }
    return activeFillColor
}

const inactiveFillColor = "#FFFFFF"; // 非アクティブ時塗りつぶしカラー(共通)

const selectedFillColor = "#FF5A5A"; // Infoでの表示時塗りつぶしカラー(共通)

export {
    carIconSvgPath,
    carIconSvgRotateOffset,
    strokeColor,
    activeFillColor,
    inactiveFillColor,
    selectedFillColor,
};
