import React, { Fragment } from 'react';
import Select from 'react-select';
import {SelectStyles} from "../SelectStyles";

type SingleProps = {
    user: any;
};

export const GroupSingleSelect: React.FC<SingleProps> = ({ user }) => {
    const displaySelect = [user];

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={displaySelect[0]}
                isDisabled={true}
                name="group"
                options={displaySelect}
            />
        </Fragment>
    );
}
