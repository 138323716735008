import React, {useState} from "react";
import DisplayActivesMap from "../../common/maps/actives/DisplayActiveMap";
import TabChanges from "../TabChanges";

type Props = {
    object: any;
    group_selected: any;
    all_groups: any;
    user_list: any;
    pd_user_map: any;
};

const ActivesMap: React.FC<Props> = ({ object, group_selected, all_groups, user_list, pd_user_map }) => {
    // Actives地図描画データ
    const [activeMapData, setActiveData] = useState(false);

    return (
        <div style={{ overflow: 'hidden', height: "100%" }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <DisplayActivesMap object={ activeMapData } user_list={ user_list } pd_user_map={ pd_user_map } />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <TabChanges state_data={ setActiveData }
                            tab_name={ object }
                            tab_value={ 0 }
                            group_selected={group_selected}
                            all_groups={all_groups}></TabChanges>
            </div>
        </div>
    );
};

export default ActivesMap;
