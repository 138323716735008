import React from 'react';
import {useRecoilState} from "recoil";
import {movieTypeState} from "../../grobalStates/atoms/movieTypeAtom";
import {StyledRadioButton} from "../StyledRadioButton";

type SingleProps = {
};
export const RadioButton: React.FC<SingleProps> = () => {
    // recoilでLive/Recordedの情報を取得
    const [defaultMovieType, setMovieData] = useRecoilState(movieTypeState);
    const handleChange = (e:any) => {
        setMovieData(e.target.value)
    };

    return (
        <div>
            {/* radio */}
            <div style={{color: "white"}}>
                <div style={{width: "180px", height: "30px", float: 'left'}}>
                    <StyledRadioButton
                        type="radio"
                        value="Live"
                        onChange={handleChange}
                        checked={defaultMovieType === 'Live'}
                    />
                    <label style={{ fontSize: "20px" }}>Live</label>
                </div>
                <div style={{height: "30px", float: 'right'}}>
                    <StyledRadioButton
                        type="radio"
                        value="Recorded"
                        onChange={handleChange}
                        checked={defaultMovieType === 'Recorded'}
                    />
                    <label style={{ fontSize: "20px" }}>Recorded</label>
                </div>
            </div>
        </div>
    );
}
