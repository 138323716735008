import React from "react";
import SetActivesMap from "./SetActivesMap";
import Map from "../../../../pages/Map";

type Props = {
    object: any;
    user_list?: any;
    pd_user_map?: any;
};

const DisplayActivesMap: React.FC<Props> = ({ object, user_list, pd_user_map }) => {
    if (object){
        const actives = object[0] as unknown as any;
        const inactives = object[1] as unknown as any;

        return (
            <SetActivesMap actives={ actives } inactives={ inactives } user_list={ user_list } pd_user_map={ pd_user_map } />
        );
    } else {
        return (
            <Map/>
        );
    }
};


export default DisplayActivesMap;
