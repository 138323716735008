import React, {useState} from "react";
import SearchConditions from "./tab_statics/SearchConditions";
import {GeneralCheckBox} from "../../../../form/general/statics/general_check_box";

type Props = {
    state_data: any;
    tab_name: any;
    user: any;
    all_pds: any;
};

const TabStatics: React.FC<Props> = ({state_data, tab_name, user, all_pds }) => {
    tab_name('statics')

    const [endDayData, setEndDayData] = useState(new Date());

    return (
        <div>
            <SearchConditions user={user} pd_selection={all_pds} endDay={setEndDayData}/>
            <GeneralCheckBox group={user} endDay={endDayData} state_data={state_data}/>
        </div>
    );
};

export default TabStatics;
