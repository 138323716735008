import React from "react";
import Map from "../../../pages/Map";
import DefaultTabChanges from "../DefaultTabChanges";

const DefaultMap: React.FC = () => {
    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <Map />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <DefaultTabChanges></DefaultTabChanges>
            </div>
        </div>
    );
};

export default DefaultMap;
