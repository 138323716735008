// Infoタブの親ファイル
import React, { useEffect, useState } from 'react';
import SearchConditions from "./tab_info/SearchConditions";
import ResultBoxLayouts from "./tab_info/ResultBoxLayouts";
import {useRecoilState} from "recoil";
import {pdsListState} from "../../../../grobalStates/atoms/pdsListAtom";
import NoResultBoxLayouts from "../../common/tabs/tab_info/NoResultBoxLayouts";

type Props = {
    state_data: any;
    tab_name: any;
    user: any;
    all_pds: any
};

const TabInfo: React.FC<Props> = ({state_data, tab_name, user, all_pds }) => {
    tab_name('info')

    const [searchData, setSearchData] = useState(false);

    // recoilでPDの情報を取得
    const [pdData, setPdData] = useRecoilState(pdsListState);

    // Map経由で選択値のlabelが設定されていない場合は、一覧から検索する
    if (pdData.label === "") {
        const pd = all_pds.find((data: any) => data.value === pdData.value);
        if (pd) setPdData(pd);
    }

    // PdIdのバリューがAllの場合はボックスを表示しない
    const select_pd = Object.values(pdData);
    const pd_label = select_pd[1];
    if (pd_label !== 'All') {
        return (
            <div>
                <SearchConditions user={user} search_result={setSearchData} pd_selection={all_pds} />
                <ResultBoxLayouts search_result={searchData} state_data={state_data} />
            </div>
        );
    } else {
        return (
            <div>
                <SearchConditions user={user} search_result={setSearchData} pd_selection={all_pds} />
                <NoResultBoxLayouts/>
            </div>
        );
    }
};

export default TabInfo;
