import React, {useState} from "react";
import DisplayActivesMap from "../../common/maps/actives/DisplayActiveMap";
import TabChanges from "../TabChanges";

type Props = {
    object: any;
    user: any;
    allPds: any;
    userList: any;
    pdUserMap: any;
};

const ActivesMap: React.FC<Props> = ({ object, user, allPds, userList, pdUserMap}) => {
    // Actives地図描画データ
    const [activeMapData, setActiveData] = useState(false);

    return (
        <div style={{ overflow: 'hidden', height: "100%" }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <DisplayActivesMap object={ activeMapData } user_list={ userList } pd_user_map={ pdUserMap } />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <TabChanges state_data={ setActiveData }
                            tab_name={ object }
                            tab_value={ 0 }
                            user={user}
                            all_pds={allPds}></TabChanges>
            </div>
        </div>
    );
};

export default ActivesMap;
