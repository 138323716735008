import React, {useEffect, useState} from "react";
import {usePrevious} from "react-use";
import {GroupSingleSelect} from "../../../../../form/statics/group_single_select";
import {StartDaySelect} from "../../../../../form/statics/start_day_select";
import {PDIdSelect} from "../../../../../form/statics/pd_id_select";
import {EndDaySelect} from "../../../../../form/statics/end_day_select";
import {useRecoilValue, useSetRecoilState} from "recoil";
import {groupListState} from "../../../../../grobalStates/atoms/groupListAtom";
import {pdsListState} from "../../../../../grobalStates/atoms/pdsListAtom";

type Props = {
    user: any;
    pd_selection: any;
    endDay: any;
};

const SearchConditions: React.FC<Props> = ({ user, pd_selection, endDay }) => {
    // グループが変更された時にPDのデフォルトを初期化
    const defaultGroup = useRecoilValue(groupListState);
    const setPd = useSetRecoilState(pdsListState);
    useEffect(() => {
        setPd({value: 'All', label: 'All'});
    }, [defaultGroup]);

    return (
        <div style={{ backgroundColor: '#50687e', paddingBottom: '20px' }}>
            <div>
                <div>
                    <div style={{ display: "flex" }}>
                        {/* Group */}
                        <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Group</label>
                        <GroupSingleSelect user={ user }/>
                    </div>
                    <div style={{ display: "flex", marginTop: '8px' }}>
                        {/* User */}
                        <label htmlFor="search-keyword" style={{width: "90px", fontSize: "20px", color: 'white' }}>User</label>
                        <PDIdSelect selection={pd_selection}/>
                    </div>
                    <div style={{ marginTop: '8px', height: '40px'}}>
                        <div style={{ display: "flex" }}>
                            <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Date</label>
                            <div style={{width: '360px', float: 'right'}}>
                                <div style={{width: '43%', float: 'left'}}>
                                    <StartDaySelect/>
                                </div>
                                <label style={{marginLeft: '14px', paddingTop: '4px', color: "white", fontSize: "20px"}}>〜</label>
                                <div style={{width: '43%',float: 'right'}}>
                                    <EndDaySelect value_info={ endDay }/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchConditions;
