import React, { Fragment } from 'react';
import Select from 'react-select';
import {useRecoilState} from "recoil";
import {timeState} from "../../grobalStates/atoms/timeAtom";
import {SelectStyles} from "../SelectStyles";

type Props = {
    disable_type: any;
    option: any;
    resultInfo: any;
    search_result: any;
};

export const FromMapTimeSelect: React.FC<Props> = ( { disable_type, option, resultInfo, search_result }) => {
    // onChangeで選択されたvalueの情報からresultInfoを選択しsearch_resultとして返却
    const [defaultTime, setTimeData] = useRecoilState(timeState);

    const handleChange = (e:any) => {
        // onChangeで選択されたvalueをatomで保持(動画検索用)
        setTimeData(e)
        if (resultInfo){
            const eData = Object.values(e);
            const filterId = eData[0];
            const target = resultInfo.filter((data: any) => data.id === filterId);
            search_result(target);
        }
    };

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                onChange={handleChange}
                className="time_select"
                classNamePrefix="time_select"
                defaultValue={defaultTime}
                isDisabled={disable_type}
                name="time_select"
                options={option}
            />
        </Fragment>
    );
}
