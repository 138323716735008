import React from "react";
import Map from "../../../../pages/Map";
import SetInfoMap from "./SetInfoMap";

type Props = {
    object: any;
};

const DisplayInfoMap: React.FC<Props> = ({ object }) => {
    if (object){
        const current_pds = Object.values(object).map(current_pds => current_pds);
        const currentPosition = current_pds[0] as unknown as any;

        return (
            <SetInfoMap currentPosition={ currentPosition } />
        );
    } else {
        return (
            <Map/>
        );
    }
};

export default DisplayInfoMap;
