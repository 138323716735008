import React, {Component, useEffect, useState} from 'react'
import DisplayLiveMovieInfo from "./DisplayLiveMovieInfo";
import {requestStreamingInput, requestStreamingType} from "../../../../../API";
import {API, graphqlOperation} from "aws-amplify";
import {requestStreaming} from "../../../../../graphql/queries";
import {useRecoilValue} from "recoil";
import {pdsListState} from "../../../../../grobalStates/atoms/pdsListAtom";
import NoResultBoxLayouts from "../../../common/tabs/tab_info/NoResultBoxLayouts";


type Props = {
    state_data: any;
};

// Live動画を表示
const PreCheckLiveMovie: React.FC<Props> = ({state_data}) => {
    // requestStreaming
    const [preMovieLoaded, setPreMovieLoaded] = useState(false);
    const [preLiveMovieData, setPreLiveMovieData] = useState<[requestStreamingType] | []>([]);

    // recoilでpd_idを取得
    const pdData = useRecoilValue(pdsListState);
    const select_pd = Object.values(pdData);
    const pd_id = select_pd[0];
    const input_pd_id = Number(pd_id);

    const streamingCountMax = 1080; // タイマー最大値(x10秒)

    let streamingCount = 0; // 10秒周期カウンター

    let message = "";

    //　初回リクエスト
    const initStreamingExec = async (input_pd_id: number) => {
        const initPdIdAndStatusInput: requestStreamingInput = {
            pdId: input_pd_id,
            status: 1,
        };
        streamingCount++;
        try {
            // // 模擬プログラム実行では不要、かつprismaへのqueryが壊れるため一旦コメントアウト
            console.log("requestStreming:id="+initPdIdAndStatusInput.pdId+",status="+initPdIdAndStatusInput.status);
            console.log("streamingCount="+streamingCount);
            if ( streamingCount < streamingCountMax ){
              const response = await API.graphql(graphqlOperation(requestStreaming, { input: initPdIdAndStatusInput }));
              // @ts-ignore
              // setPreLiveMovieData(response.data.requestStreaming);
              setPreLiveMovieData({statusCode: 200});
              setPreMovieLoaded(true);
            } else {
              console.log("no request / streamingCount="+streamingCount);
            }
        } catch (e) {
            console.log(e);
        }
    };

    // グループが変化したときに実行
    useEffect(() => {
        streamingCount = 0; // タイムアウト用
        initStreamingExec(input_pd_id);
    }, [input_pd_id]);

    // setInterval用のカウント10秒経過でuseEffectを走らせる
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('requestStreaming10秒経過の再リクエストをしました');
            initStreamingExec(input_pd_id);
        }, 10000);
        return () => clearInterval(interval);
    }, [input_pd_id]);

    // ローディング中かの条件
    if (preMovieLoaded){
        // requestStreamingステータスコードの確認
        const http_status = Object.values(preLiveMovieData)
        const result_status = http_status[0] as unknown as string;

        if (result_status == '200'){
            return (
                <DisplayLiveMovieInfo pd_value={input_pd_id} state_data={state_data}/>
            );
            // 300の時
        } else if (result_status == '300'){
            message = "300: PD取得エラー(pdIdのDBが存在しない or 権限がない)";
            // 301の時
        } else if (result_status == '301'){
            message = "301: PD非アクティブエラー(PDが非アクティブ状態)";
            // 302の時
        } else {
            message = "302: ステータスエラー(入力値statusが不正)";
        }
    } else {
        message = "loading...";
    }

    return <NoResultBoxLayouts message={message}/>;
};

export default PreCheckLiveMovie;
