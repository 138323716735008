// Activesタブの親ファイル
import React from 'react';
import { GetPdSummaryInfo } from "../../common/tabs/tab_actives/GetPdSummaryInfo";
import {SingleSelect} from "../../../../form/general/actives/single_select";

type Props = {
    state_data: any;
    tab_name: any;
    user: any;
};

const TabActives: React.FC<Props> = ({state_data, tab_name, user }) => {
    tab_name('actives')

    const id = user[0];
    const user_id = Number(id);

    return(
        <div>
            <div style={{ backgroundColor: '#50687e' }}>
                <div style={{ display: "flex", paddingBottom: '20px' }}>
                    <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white'}}>Group</label>
                    <SingleSelect user={user}/>
                </div>
            </div>
            <GetPdSummaryInfo user_group_id={user_id} state_data={state_data}/>
        </div>
    );
};

export default TabActives;
