import React, {useEffect, useState} from "react";
import SearchCondition from "./SearchConditions";
import ResultBoxLayouts from "./ResultBoxLayouts";
import {useRecoilState} from "recoil";
import {pdsListState} from "../../../../../grobalStates/atoms/pdsListAtom";
import NoResultBoxLayouts from "../../../common/tabs/tab_info/NoResultBoxLayouts";

// selectable(全グループのリスト)
// group_value(ステートを変更する際に利用)
// map_result(mapの描画用)
// pds(全PDのリスト)
type Props = {
    selectable: any;
    map_result: any;
    pds: any;
};

const DisplayInfoTab: React.FC<Props> = ({selectable, map_result, pds }) => {

    const [searchData, setSearchData] = useState(false);

    // recoilでPDの情報を取得
    const [pdData, setPdData] = useRecoilState(pdsListState);

    // Map経由で選択値のlabelが設定されていない場合は、一覧から検索する
    if (pdData.label === "") {
        const pd = pds.find((data: any) => data.value === pdData.value);
        if (pd) setPdData(pd);
    }
    // PdIdのバリューがAllの場合はボックスを表示しない
    const select_pd = Object.values(pdData);
    const pd_label = select_pd[1];
    if (pd_label !== 'All') {
        return (
            <div>
                <SearchCondition selectable={selectable} search_result={setSearchData} pds={pds}/>
                <ResultBoxLayouts search_result={searchData} state_data={map_result}></ResultBoxLayouts>
            </div>
        );
        // User(PDidがAllの時)
    } else {
        return (
            <div>
                <SearchCondition selectable={selectable} search_result={setSearchData} pds={pds}/>
                <NoResultBoxLayouts/>
            </div>
        );
    }
};

export default DisplayInfoTab;
