import React from 'react';
import GetPds from "./tab_info/GetPds";
import {useRecoilValue} from "recoil";
import {groupListState} from "../../../../grobalStates/atoms/groupListAtom";

type Props = {
    state_data: any;
    tab_name: any;
    all_groups: any;
};

// Infoタブの親ファイル
const TabInfo: React.FC<Props> = ({state_data, tab_name, all_groups }) => {
    tab_name('info')

    // recoilでグループの情報を取得
    const group = useRecoilValue(groupListState);
    let userGroupId = null;

    if (group){
        const select_value = Object.values(group)
        const value = select_value[0];

        if (value !== 'All') userGroupId = Number(value);
    }

    return(
        <GetPds selectable={ all_groups } map_result={state_data} user_group_id={userGroupId}/>
    );
};

export default TabInfo;
