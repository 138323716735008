import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DefaultTab from "./tabs/DefaultTab";

interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}

const tabStyle = (flag: boolean) => {
    if (flag) {
        return {
            // textTransformはここで指定すると何故か型エラーとなるため後で追加
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "18px",
            backgroundColor: '#50687e',
            opacity: 1,
            borderTop: '1px solid #a8b4c0',
            borderLeft: '1px solid #a8b4c0',
            borderRight: '1px solid #a8b4c0',
            borderRadius: "10px 10px 0 0",
            letterSpacing: "2px",
        }
    } else {
        return {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "18px",
            backgroundColor: '#5f7d95',
            opacity: 1,
            borderTop: '1px solid #a8b4c0',
            borderLeft: '1px solid #a8b4c0',
            borderRight: '1px solid #a8b4c0',
            borderBottom: '1px solid #a8b4c0',
            borderRadius: "10px 10px 0 0",
            letterSpacing: "2px",
        }
    }
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    paddingTop: 20,
                                }}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const DefaultTabChanges: React.FC = () => {
    const theme = useTheme();
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index: number) => {
        setValue(index);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <AppBar position="static" style={{ backgroundColor: '#5f7d95' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: '0px'
                        }
                    }}
                >
                    <Tab label="Actives" {...a11yProps(0)} style={{...tabStyle(value === 0), textTransform: "none"}} />
                    <Tab label="Statics" {...a11yProps(1)} style={{...tabStyle(value === 1), textTransform: "none"}} />
                    <Tab label="Info" {...a11yProps(2)} style={{...tabStyle(value === 2), textTransform: "none"}} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={value}
                onChangeIndex={handleChangeIndex}
                style={{ height: "calc(100vh - 190px)", overflowY: "auto", backgroundColor: '#50687e', borderLeft: '1px solid #a8b4c0', borderRight: '1px solid #a8b4c0', borderBottom: '1px solid #a8b4c0' }}
            >
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <DefaultTab></DefaultTab>
                </TabPanel>
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <DefaultTab></DefaultTab>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <DefaultTab></DefaultTab>
                </TabPanel>
            </SwipeableViews>
        </Box>
    );
};

export default DefaultTabChanges;
