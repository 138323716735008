import React from "react";
import TracingGraph from "../../../general_role/tabs/tab_statics/graphs/TracingGraph";
import AlertsGraph from "../../../general_role/tabs/tab_statics/graphs/AlertsGraph";
import EmergencyCallsGraph from "../../../general_role/tabs/tab_statics/graphs/EmergencyCallsGraph";
import { StyledCheckBoxText, StyledCheckBox } from "../../../../../form/StyledCheckBox";

const NoGraphDataLayouts: React.FC = () => {
    return (
        <div style={{ padding: '10px 10px 10px 10px', color: "white", fontSize: "20px" }}>
            {/* Travels */}
            <div style={{marginBottom: '10px'}}>
                <div style={{marginBottom: '10px'}}>
                    <label htmlFor="Travels">
                        <div style={{float: 'left', marginBottom: '10px'}}>
                            <StyledCheckBox
                                type="checkbox"
                                id="Travels"
                                name="Travels"
                                value="Travels"
                                checked={false}
                            />
                            <StyledCheckBoxText>Travels</StyledCheckBoxText>
                        </div>
                    </label>
                    <div style={{float: 'right', fontWeight: 500}}>
                        <label style={{ marginRight: '20px'}}>Total</label>
                        <label style={{ marginRight: '10px'}}></label>
                    </div>
                </div>
                <TracingGraph result={null}/>
            </div>
            {/* Alerts */}
            <div style={{marginBottom: '10px'}}>
                <div>
                    <label htmlFor="Alerts">
                        <div style={{float: 'left', marginBottom: '10px'}}>
                            <StyledCheckBox
                                type="checkbox"
                                id="Alerts"
                                name="Alerts"
                                value="Alerts"
                                checked={false}
                            />
                            <StyledCheckBoxText>Alerts</StyledCheckBoxText>
                        </div>
                    </label>
                    <div style={{float: 'right', fontWeight: 500}}>
                        <label style={{ marginRight: '20px'}}>Total</label>
                        <label style={{ marginRight: '10px'}}></label>
                    </div>
                </div>
                <AlertsGraph result={null}/>
            </div>
            {/* Calls */}
            <div>
                <div>
                    <label htmlFor="Calls">
                        <div style={{float: 'left', marginBottom: '10px'}}>
                            <StyledCheckBox
                                type="checkbox"
                                id="Calls"
                                name="Calls"
                                value="Calls"
                                checked={false}
                            />
                            <StyledCheckBoxText>Calls</StyledCheckBoxText>
                        </div>
                    </label>
                    <div style={{float: 'right', fontWeight: 500}}>
                        <label style={{ marginRight: '20px'}}>Total</label>
                        <label style={{ marginRight: '10px'}}></label>
                    </div>
                </div>
                <EmergencyCallsGraph result={null}/>
            </div>
        </div>
    );
};

export default NoGraphDataLayouts;
