import React, {useState} from "react";
import SearchCondition from "./SearchCondition";
import {CheckBox} from "../../../../../form/statics/check_box";

// selectable(全グループのリスト)
// group_value(ステートを変更する際に利用)
// map_result(mapの描画用)
// pds(全PDのリスト)
type Props = {
    selectable: any;
    map_result: any;
    pds: any;
};

const DisplayStaticsTab: React.FC<Props> = ({selectable, map_result, pds }) => {
    const [endDayData, setEndDayData] = useState(new Date());

    return (
        <div>
            <SearchCondition selectable={selectable} pds={pds} endDay={setEndDayData}/>
            <CheckBox endDay={endDayData} mapResult={map_result} />
        </div>
    );
};

export default DisplayStaticsTab;
