import React, {useEffect, useState} from "react";
import {TimeSelect} from "../../../../../form/info/time_select";
import {useRecoilValue} from "recoil";
import {calendarFromState} from "../../../../../grobalStates/atoms/calendarFromAtom";
import {notificationKindState} from "../../../../../grobalStates/atoms/notificationKindAtom";
import {pdsListState} from "../../../../../grobalStates/atoms/pdsListAtom";
import {pdAlertsInput, pdAlertsType} from "../../../../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getPdAlerts} from "../../../../../graphql/queries";

type Props = {
    search_result: any;
    end_day: any;
    disable_type: any;
};

const ResultTimeBox: React.FC<Props> = ({ search_result, end_day, disable_type }) => {
    ////  アラート時間選択
    const [timesLoaded, setTimesLoaded] = useState(false);
    const [timeData, setTimeData] = useState<[pdAlertsType] | []>([]);

    // recoilでpdIdとを取得
    const pd = useRecoilValue(pdsListState);
    const targetPd = Object.values(pd)
    const pdValue = targetPd[0];
    const inputPDValue = Number(pdValue);

    // アラートタイプ(Alert || Calls )
    const notificationKind = useRecoilValue(notificationKindState);
    const targetAlertType = Object.values(notificationKind);
    const alertTypeValue = targetAlertType[0];
    const inputAlertTypeValue = Number(alertTypeValue);

    // 日付の開始日
    const calendarFrom = useRecoilValue(calendarFromState);

    //  日付の終了日
    const calendarEnd = end_day;


    // getPdAlerts ストリーミング情報取得初回
    const initTimeExec = async (inputPDValue: number, inputAlertTypeValue: number, calendarFrom: any, calendarEnd: string) => {
        const pdAlertsInput: pdAlertsInput = {
            pdId: inputPDValue,
            alertType: inputAlertTypeValue,
            dateFrom: calendarFrom,
            dateTo: calendarEnd,
        };
        try {
            const response = await API.graphql(graphqlOperation(getPdAlerts, { input: pdAlertsInput }));
            // @ts-ignore
            setTimeData(response.data.getPdAlerts);
            setTimesLoaded(true);
        } catch (e) {
            console.log(e);
        }
    };

    // 検索条件が変化した時
    useEffect(() => {
        initTimeExec(inputPDValue, inputAlertTypeValue, calendarFrom, calendarEnd);
    }, [inputPDValue, inputAlertTypeValue, calendarFrom, calendarEnd]);

    if (timesLoaded){
        const timeResult = Object.values(timeData);
        const statusCode = timeResult[0];
        const code = Number(statusCode);
        if (code == 200){
            const resultData = timeResult[1];
            const selectOption = [];
            selectOption.push({value: 'Time', label: 'Time'})
            const result = Object.values(resultData);
            const arrays = result[0] as any;
            for (let i = 0; i < arrays.length; i++){
                const display = arrays[i] as unknown as any;
                const array = Object.values(display);
                const value = array[0] as string;
                const label = array[1] as string;
                selectOption.push({value: value, label: label});
            }
            return (
                <div style={{ marginTop: "10px" }}>
                    <TimeSelect disable_type={disable_type} option={selectOption} resultInfo={arrays} search_result={search_result}/>
                </div>
            );
        } else {
            const selectOption = [{value: 'Time', label: 'Time'}];
            return (
                <div style={{ marginTop: "10px" }}>
                    <TimeSelect disable_type={disable_type} option={selectOption} resultInfo={null} search_result={search_result}/>
                </div>
            );
        }
    } else {
        const selectOption = [{value: 'Time', label: 'Time'}];
        return (
            <div style={{ marginTop: "10px" }}>
                <TimeSelect disable_type={disable_type} option={selectOption} resultInfo={null} search_result={search_result}/>
            </div>
        );
    }
};

export default ResultTimeBox;
