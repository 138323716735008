import React, { Fragment } from 'react';
import Select from 'react-select';
import {useRecoilState, useSetRecoilState} from "recoil";
import {pdsListState} from "../../grobalStates/atoms/pdsListAtom";
import {SelectStyles} from "../SelectStyles";
import {timeState} from "../../grobalStates/atoms/timeAtom";

type SingleProps = {
    selection: any;
};

export const PDIdSelect: React.FC<SingleProps> = ({ selection }) => {
    const [defaultPd, setPd] = useRecoilState(pdsListState);
    const setTime = useSetRecoilState(timeState);
    const onChange = (target: any) => {
        setTime({value: 'Time', label: 'Time'});
        setPd(target)
    };

    return (
        <Fragment>
            <Select
                styles={SelectStyles}
                onChange={onChange}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={defaultPd}
                isDisabled={false}
                name="pd_id"
                options={selection}
            />
        </Fragment>
    );
}
