import React from 'react';
import {SelectableUserGroups} from "./tab_actives/SelectableUserGroups";

type Props = {
    state_data: any;
    tab_name: any;
    all_groups: any;
};

// 管理者ロールのActivesタブの親ファイル
const TabActives: React.FC<Props> = ({state_data, tab_name, all_groups }) => {
    tab_name('actives')

    return (
        <SelectableUserGroups user_group={all_groups} state_data={state_data}/>
    );
};

export default TabActives;
