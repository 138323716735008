export const SelectStyles = {
    // ボックス全体
    control: () => ({
        backgroundColor: "#e7e7e7",
        borderRadius: "5px",
        fontFmamily: "Noto Sans CJK JP",
        height: 28,
        width: 360,
        display: "flex",
    }),
    // リスト全体
    menu: (provided: any) => ({
        ...provided,
        backgroundColor: "#e7e7e7",
        width: 360,
    }),
    // リストの各項目
    option: (provided: any, state: any) => ({
        ...provided,
        backgroundColor: state.isSelected ? "#3C84FF !important" : "#e7e7e7",
        borderBottom: "1px dotted black",
        padding: "0px 20px",
        fontSize: 16,
        width: 360,
        "&:hover": {
            backgroundColor: state.isFocused ? "#DEEBFF" : "#e7e7e7",
        }
    }),
    // inputボックス
    input: (provided: any) => ({
        ...provided,
        marginTop: 0,
        paddingTop: 0,
    }),
    valueContainer: (provided: any) => ({
        ...provided,
        paddingTop: 0,
    }),
    singleValue: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        return {
            ...provided,
            opacity,
            color: "#50697e",
            fontSize: 16,
            marginTop: 0,
        };
    },
    // セパレータ
    indicatorSeparator: () => ({}),
    // ドロップダウンアイコン
    indicatorsContainer: (provided: any, state: any) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        return {
            ...provided,
            opacity,
            width: 18,
            height: 12,
            marginTop: 8,
            marginRight: 6,
            backgroundSize: "contain",
            backgroundImage: `url("${process.env.PUBLIC_URL}/static/mock_images/dropdown_indicator.png")`,
        };
    },
    dropdownIndicator: (provided: any) => ({
        ...provided,
        display: "none",
    }),
};
