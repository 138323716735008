// Atomを管理するキー
// Atom: 状態の単位。
// Atom更新時にコンポーネントは再レンダリング
export enum AtomKeys {
    VIA_TYPE_STATE = "viaTypeState",
    TAB_CHANGE_STATE = "tabChangeState",
    GROUP_LIST_STATE = "groupListState",
    PDS_LIST_STATE = "pdsListState",
    MOVIE_TYPE_STATE = "movieTypeState",
    CALENDAR_FROM_STATE = "calendarFromState",
    NOTIFICATION_KIND_STATE = "notificationKindState",
    TIME_STATE = "timeState",
}

// Selectorを管理するキー
// selector: atomまたは他のselectorを入力として受け取る関数。
// 同期的・非同期的に状態変化
// 入力として受け取ったatom、または入力として受け取ったselector更新時selectorは再計算。
// selector更新時に再レンダリング
export enum SelectorKeys {
    VIA_TYPE_SELECTOR = "viaTypeSelector",
    TAB_CHANGE_SELECTOR = "tabChangeSelector",
    GROUP_LIST_SELECTOR = "groupListSelector",
    PDS_LIST_SELECTOR = "pdsListSelector",
    MOVIE_TYPE_SELECTOR = "movieTypeSelector",
    CALENDAR_FROM_SELECTOR = "calendarFromSelector",
    NOTIFICATION_KIND_SELECTOR = "notificationKindSelector",
    TIME_SELECTOR = "timeSelector",
}
