// AlertsGraph
import React from 'react'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

type Props = {
    result: any;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

export const options = {
    responsive: false,
    plugins: {
        legend: {
            display: false,
        },
        title: {
            display: false,
        },
    },
    scales: {
        xAxes: {
            ticks: {
                color: 'white',
            }
        },
        y: {
            beginAtZero: true,
            ticks: {
                color: 'white',
            },
            min: 0,
        }
    },
};

// グラフを表示
const AlertsGraph: React.FC<Props> = ({ result }) => {
    if (result !== null){
        const resultData = Object.values(result);
        const alertLabelData = resultData[0] as any;
        const alertValueData = resultData[1] as any;
        const labels = alertLabelData;
        const data = {
            labels,
            datasets: [
                {
                    label: 'Daily Data',
                    data: alertValueData.map((data: number) => data),
                    borderColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(255,255,255,1)',
                }
            ],
        };
        return (
            <Bar options={options} data={data} width={415} height={170} />
        );
    } else {
        const labels = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
        ];
        const data = {
            labels,
            datasets: [
                {
                    label: 'Daily Data',
                    data: labels.map(() => 0),
                    borderColor: 'rgb(255, 255, 255)',
                    backgroundColor: 'rgba(255,255,255,1)',
                }
            ],
        };
        return (
            <Bar options={options} data={data} width={415} height={170} />
        );
    }
};

export default AlertsGraph;
