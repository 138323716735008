import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"

type Props = {
    value_info: any;
    disable_type: any;
};

export const EndDaySelect: React.FC<Props> = ( { value_info, disable_type }) => {
    // 今日の日付を定義
    const Today = new Date();
    const lastYear = new Date();
    lastYear.setDate(lastYear.getDate() - 366);
    const [date, setValueDate] = useState(Today);
    // 日付が変化したときに実行
    useEffect(() => {
        value_info(date);
    }, [date]);

    return (
        <div>
            <style>
                {`.date-picker input {width: 100%}`}
            </style>
            <DatePicker selected={date}
                        wrapperClassName="date-picker"
                        dateFormat="yyyy/MM/dd"
                        disabled={disable_type}
                        maxDate={Today}
                        minDate={lastYear}
                        onChange={selectedDate => {setValueDate(selectedDate || Today)}}/>
        </div>
    );
}