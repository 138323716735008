import * as React from 'react';
import {useState} from "react";
import ActivesMap from "./maps/ActivesMap";
import StaticsMap from "./maps/StaticsMap";
import InfoMap from "./maps/InfoMap";

type Props = {
    allGroup: any;
    userList: any;
    pdUserMap: any;
};

export const MapAndTabChange: React.FC<Props> = ( { allGroup, userList, pdUserMap  }) => {
    // タブで選択したグループを引き継ぐ(初期値はAll)
    const [selectGroupNameData, setSelectGroupName] = useState({value: 'All', label: 'All'});
    // タブで地図の描画を変更
    const [tabNameData, setTabName] = useState('actives')
    const tabName = tabNameData as unknown as string

    // タブがactivesの時
    if (tabName == 'actives') {
        const selectValue = Object.values(selectGroupNameData)
        const value = selectValue[0]
        if (value === 'All'){
            return(
                <ActivesMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup} user_list={userList} pd_user_map={pdUserMap}></ActivesMap>
            );
        } else {
            return(
                <ActivesMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup} user_list={userList} pd_user_map={pdUserMap}></ActivesMap>
            );
        }
        // タブがstaticsの時
    } else if (tabName == 'statics') {
        const selectValue = Object.values(selectGroupNameData)
        const value = selectValue[0]
        if (value === 'All'){
            // 選択されたグループがAllの時
            return (
                <StaticsMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup}></StaticsMap>
            );
        } else {
            return (
                <StaticsMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup}></StaticsMap>
            );
        }
        // タブがinfoの時
    } else {
        const selectValue = Object.values(selectGroupNameData)
        const value = selectValue[0]
        if (value === 'All'){
            return (
                <InfoMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup}></InfoMap>
            );
        } else {
            return (
                <InfoMap object={setTabName} group_selected={setSelectGroupName} all_groups={allGroup}></InfoMap>
            );
        }
    }
};

export default MapAndTabChange;
