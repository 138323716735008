import styled from "styled-components";

const StyledCheckBoxText = styled.div`
    align-items: center;
    display: flex;
`;

const StyledCheckBox = styled.input`
  display: none;
  + ${StyledCheckBoxText} {
    &::before {
        background-image: url(${process.env.PUBLIC_URL}/static/mock_images/checkbox-off.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        content: "";
        height: 28px;
        width: 28px;
        position: relative;
        margin-right: 20px;
    }
  }
  &:checked + ${StyledCheckBoxText} {
    &::before {
      background-image: url(${process.env.PUBLIC_URL}/static/mock_images/checkbox-on.png);
    }
  }
`;

export { StyledCheckBoxText, StyledCheckBox };
