import React from "react";
import {GroupSingleSelect} from "../../../../../form/admin/info/group_single_select";
import {PDIdSelect} from "../../../../../form/info/pd_id_select";
import {StartDaySelect} from "../../../../../form/statics/start_day_select";
import {EndDaySelect} from "../../../../../form/statics/end_day_select";

// selectable(全グループのリスト)
// pds(全PDのリスト)
type Props = {
    selectable: any;
    pds: any;
    endDay: any;
};

const SearchCondition: React.FC<Props> = ({selectable, pds, endDay }) => {
    return (
        <div>
            <div style={{ backgroundColor: '#50687e', paddingBottom: '20px' }}>
                <div>
                    <div style={{ display: "flex" }}>
                        {/* Group */}
                        <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Group</label>
                        <GroupSingleSelect group={ selectable } />
                    </div>
                    <div style={{ display: "flex", marginTop: '8px' }}>
                        {/* ID */}
                        <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>User</label>
                        <PDIdSelect selection={ pds }/>
                    </div>
                    <div style={{ marginTop: '8px', height: '40px'}}>
                        <div style={{ display: "flex" }}>
                            <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Date</label>
                            <div style={{width: '360px', float: 'right'}}>
                                <div style={{width: '43%', float: 'left'}}>
                                    <StartDaySelect/>
                                </div>
                                <label style={{marginLeft: '14px', paddingTop: '4px', color: "white", fontSize: "20px"}}>〜</label>
                                <div style={{width: '43%',float: 'right'}}>
                                    <EndDaySelect value_info={endDay}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SearchCondition;
