import React from "react";
import { GoogleMap, LoadScript, Marker, InfoBox, Polyline } from "@react-google-maps/api";
import { MapStyles } from "../../../MapStyles";
import {useSetRecoilState} from "recoil";
import {viaTypeState} from "../../../../../grobalStates/atoms/viaTypeAtom";
import {pdsListState} from "../../../../../grobalStates/atoms/pdsListAtom";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import {timeState} from "../../../../../grobalStates/atoms/timeAtom";
import {notificationKindState} from "../../../../../grobalStates/atoms/notificationKindAtom";

const infoboxStyle: React.CSSProperties = {
    position: "relative",
    width: "150px",
    height: "20px",
    background: "#FFFFFF",
    padding: "2px",
    textAlign: "center",
    border: "1px solid #000000",
    color: "#000000",
    fontSize: "12px",
    fontWeight: "bold",
    borderRadius: "5px",
};

const infoboxAfterStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "30%",
    left: "94%",
    borderColor: "rgba(255, 255, 255, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    borderTopColor: "#FFFFFF",
}
const infoboxBeforeStyle: React.CSSProperties = {
    border: "solid transparent",
    content: "",
    height: 0,
    width: 0,
    pointerEvents: "none",
    position: "absolute",
    top: "30%",
    left: "94%",
    borderColor: "rgba(0, 0, 0, 0)",
    borderTopWidth: "12px",
    borderBottomWidth: "12px",
    borderLeftWidth: "5px",
    borderRightWidth: "5px",
    marginLeft: "-6px",
    marginTop: "2px",
    borderTopColor: "#000000",
}

type Props = {
    travel: any;
    alert: any;
    call: any;
};

const containerStyle = {
    height: "calc(100vh - 120px)",
    width: "100%",
};

const center = {
    lat: 35.709984139,
    lng: 139.810703,
};

const options = {
    styles: MapStyles,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "hybrid"],
    },
    minZoom: 2,
};

const SetStaticsMap: React.FC<Props> = ({ travel, alert, call }) => {
    const travelsInfo = travel[0];
    const alertsInfo = alert[0];
    const callsInfo = call[0];

    // 検索条件など
    const setViaChange = useSetRecoilState(viaTypeState);
    const setPd = useSetRecoilState(pdsListState);
    const setMovieType = useSetRecoilState(movieTypeState);
    // アラートとコールを切り替える
    const setNotificationKind = useSetRecoilState(notificationKindState);
    // 発生時間を選択する
    const setTime = useSetRecoilState(timeState);

    const setInfoAlertTab = (id: number, pdId: number, _e: any) => {
        setPd({value: String(pdId), label: "" })
        setMovieType('Recorded')
        setNotificationKind({value: 1, label: 'Alert'})
        setTime({value: String(id), label: "地図Alert"})
        setViaChange('byMap')
    }

    const setInfoCallTab = (id: number, pdId: number, _e: any) => {
        setPd({value: String(pdId), label: "" })
        setMovieType('Recorded')
        setNotificationKind({value: 2, label: 'Calls'})
        setTime({value: String(id), label: "地図Call"})
        setViaChange('byMap')
    }

    const displayTravel: any = ((): any => {
        if (travelsInfo.displayFlag) {
            const travelsList = Object.values(travelsInfo.data);
            const TravelLists = travelsList.map((lists: any) => {
                const eachTravel = lists.map((list: any) => {
                    const polyData = list;
                    const polyOptions = {
                    strokeColor: '#00ff8f',
                    strokeWeight: 5,
                    clickable: false,
                    draggable: false,
                    editable: false,
                    visible: true,
                    radius: 30000,
                    paths: polyData,
                    zIndex: 1
                    };
                    return <Polyline path={polyData} options={polyOptions}/>
                })
                return eachTravel;
            })
            return TravelLists;
        }
        return [] as any;
    })();

    const displayAlerts: any = ((): any => {
        if (alertsInfo.displayFlag) {
            const alertsObject = Object.values(alertsInfo.data);
            const alertsList = alertsObject[0] as any;
            const eachAlert = alertsList.map((alert: any) => {
                const lat = alert.lat as number;
                const long = alert.long as number;
                const position = {lat: lat, lng: long};
                return   <div key={alert.id}>
                    <Marker
                        onClick={e => setInfoAlertTab(alert.id, alert.pdId, e)}
                        position={position}
                        icon={{
                            url: "/static/mock_images/z16-alert.png",
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 16)
                        }}
                    />
                </div>
            })
            return eachAlert;
        }
        return [] as any;
    })();

    const displayCalls: any = ((): any => {
        if (callsInfo.displayFlag) {
            const callsObject = Object.values(callsInfo.data);
            const callsList = callsObject[0] as any;
            const eachCall = callsList.map((call: any) => {
                const lat = call.lat as number;
                const long = call.long as number;
                const position = {lat: lat, lng: long};
                return   <div key={call.id}>
                    <Marker
                        onClick={e => setInfoCallTab(call.id, call.pdId, e)}
                        position={position}
                        icon={{
                            url: "/static/mock_images/z16-call.png",
                            scaledSize: new google.maps.Size(28, 28),
                            anchor: new google.maps.Point(14, 16)
                        }}
                    />
                </div>
            })
            return eachCall;
        }
        return [] as any;
    })();

    return (
        <div title="MAP">
            <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5} options={options}>
                    {displayTravel}
                    {displayAlerts}
                    {displayCalls}
                </GoogleMap>
            </LoadScript>
        </div>
    );
};

export default SetStaticsMap;
