import React, { useState } from 'react';
import { AllSelect } from "../../../../../form/actives/group_select";
import { GetPdSummaryInfo } from "../../../common/tabs/tab_actives/GetPdSummaryInfo";
import {useRecoilValue} from "recoil";
import {groupListState} from "../../../../../grobalStates/atoms/groupListAtom";

type Props = {
    user_group: any;
    state_data: any;
};

// 全ユーザーグループを取得
export const SelectableUserGroups: React.FC<Props> = ( { user_group, state_data } ) => {
    // recoilでグループの情報を取得
    const group = useRecoilValue(groupListState);

    let userGroupId = null;
    if (group){
        const select_value = Object.values(group)
        const value = select_value[0];

        if (value !== 'All') userGroupId = Number(value);
    }

    return (
        <div>
            <div style={{ backgroundColor: '#50687e' }}>
                <div style={{ display: "flex", paddingBottom: '20px' }}>
                    <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Group</label>
                    <AllSelect selection={ user_group }/>
                </div>
            </div>
            <GetPdSummaryInfo user_group_id={userGroupId} state_data={state_data}/>
        </div>
    );
};
