import React from "react";

type Props = {
  message?: string;
};

const NoVideoBox: React.FC<Props> = ({message}) => {
  const content = (msg: any) => {
    if (msg) {
      return <div style={{fontSize: "18px"}}>{msg}</div>
    } else {
      return <img src={`${process.env.PUBLIC_URL}/static/logo_h80.png`} alt="logo" style={{width: "80px", height: "46px"}}/>
    }
  }
  return (
    <div style={{width: "450px", height: "260px", backgroundColor: "#273540", textAlign: "center", paddingTop: "100px"}}>
      {content(message)}
    </div>
  );
}

export default NoVideoBox;
