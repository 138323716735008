import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import {calendarFromState} from "../../grobalStates/atoms/calendarFromAtom";
import {useRecoilState} from "recoil";

type Props = {
    disable_type: any;
};

export const StartDaySelect: React.FC<Props> = ( { disable_type }) => {
    // recoilからグループを継承
    const [defaultCalendarFrom, setCalendarFrom] = useRecoilState(calendarFromState);

    // 選択可能日時の調整
    const Today = new Date();
    const lastYear = new Date();
    lastYear.setDate(lastYear.getDate() - 366);

    // 日付が変化したときに実行
    useEffect(() => {
        setCalendarFrom(defaultCalendarFrom);
    }, [defaultCalendarFrom]);

    return (
        <div>
            <style>
                {`.date-picker input {width: 100%}`}
            </style>
            <DatePicker selected={defaultCalendarFrom}
                        wrapperClassName="date-picker"
                        dateFormat="yyyy/MM/dd"
                        disabled={disable_type}
                        maxDate={Today}
                        minDate={lastYear}
                        onChange={selectedDate => {setCalendarFrom(selectedDate || Today)}}/>
        </div>
    );
}