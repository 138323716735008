import React, {useState} from "react";
import TabChanges from "../TabChanges";
import DisplayStaticsMap from "../../common/maps/statics/DisplayStaticsMap";

type Props = {
    object: any;
    group_selected: any;
    all_groups: any;
};

const StaticsMap: React.FC<Props> = ({ object, group_selected, all_groups }) => {
    // Statics地図描画データ
    const [staticsMapData, setStaticsData] = useState(false);

    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <DisplayStaticsMap object={ staticsMapData } />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <TabChanges state_data={ setStaticsData } tab_name={ object } tab_value={ 1 } group_selected={group_selected} all_groups={all_groups}></TabChanges>
            </div>
        </div>
    );
};

export default StaticsMap;
