import React, {useEffect, useState} from 'react'
import {usePrevious} from "react-use";
import {pdAlertDetailInput, pdAlertDetailType} from "../../../../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getPdAlertDetail} from "../../../../../graphql/queries";
import {useRecoilValue} from "recoil";
import {DisplayMovie} from "../../../general_role/tabs/tab_info/DisplayMovie";
import NoResultBoxLayouts from "./NoResultBoxLayouts";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import {notificationKindState} from "../../../../../grobalStates/atoms/notificationKindAtom";
import IdentifyingRecordedAddress from "./IdentifyingRecordedAddress";

type Props = {
    state_data: any;
    alertId: string;
    resultTime: string;
    lat: string;
    long: string;
};

const border_style = {
    backgroundColor: '#3f4d63',
    content: '""',
    borderRadius: '2px',
    display: 'block',
    height: '4px'
}

// Record動画を表示
const DisplayRecordMovie: React.FC<Props> = ({state_data, alertId,
                                                 resultTime, lat, long}) => {
    const type_record = useRecoilValue(movieTypeState);
    const notificationKind = useRecoilValue(notificationKindState);
    const notificationData = Object.values(notificationKind);
    const notificationLabel = notificationData[1];
    // 初回に送信するため
    const [firstTime, setFirst] = useState(true);
    const oldAlertId = usePrevious(alertId);
    // 1sで送信するため
    const [secondTime, setSeconds] = useState(true);

    // recordURL
    const [movieLoaded, setMovieLoaded] = useState(false);
    const [movieData, setMovieData] = useState<[pdAlertDetailType] | []>([]);

    // ジオコーディング住所特定用
    const inputLatValue = Number(lat);
    const inputlongValue = Number(long);

    //　初回リクエスト
    const initRecordedExec = async (alertId: string) => {
        // API用に生成
        const input_alert_id = Number(alertId);
        const initAlertIdInput: pdAlertDetailInput = {
            alertId: input_alert_id,
        };
        try {
            const response = await API.graphql(graphqlOperation(getPdAlertDetail, { input: initAlertIdInput }));
            // @ts-ignore
            setMovieData(response.data.getPdAlertDetail.data);
            setMovieLoaded(true);
        } catch (e) {
            console.log(e);
        }
    };

    // アラートIDが変化したときに実行
    useEffect(() => {
        initRecordedExec(alertId);
        setSeconds(true)
    }, [alertId]);

    // ローディング中かの条件
    if (movieLoaded){
        const InfoData = Object.values(movieData);
        const speed = InfoData[0];
        const url = InfoData[1];
        const mapData =[];
        if (firstTime) {
            if (notificationLabel == 'Alert'){
                mapData.push({lat: lat, lng: long, iconType: 'alert'})
                state_data(mapData)
                setFirst(false);
            } else {
                mapData.push({lat: lat, lng: long, iconType: 'call'})
                state_data(mapData)
                setFirst(false);
            }
        } else if (secondTime && (oldAlertId !== alertId)){
            if (notificationLabel == 'Alert'){
                mapData.push({lat: lat, lng: long, iconType: 'alert'})
                state_data(mapData)
                setSeconds(false);
            } else {
                mapData.push({lat: lat, lng: long, iconType: 'call'})
                state_data(mapData)
                setSeconds(false);
            }
        }

        return (
            <div style={{color: 'white', paddingTop: '10px'}}>
                <label style={{fontSize: '20px'}}>{type_record}</label>
                <DisplayMovie url={url} />
                <div style={{marginTop: '40px'}}>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Area</label>
                        <IdentifyingRecordedAddress lat={inputLatValue} lng={inputlongValue}/>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Speed</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{speed}km/h</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Position</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{lat} / {long}</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Time</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{resultTime}</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                </div>
            </div>
        );
    } else {
        return (
            <NoResultBoxLayouts />
        );
    }
};

export default DisplayRecordMovie;
