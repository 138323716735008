import React, {useEffect, useState} from "react";
import {PdIdsInput, PdIdsPdType, UsersInput, UsersUserType} from "../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getPdIds, getUsers} from "../../graphql/queries";
import SetTabAndTab from "../templates/general_role/SetMapAndTab";
import DefaultMap from "../templates/common/maps/DefaultMap";

type Props = {
    userId: number;
    userName: string;
};

// ログインしたアカウントの権限が一般の親ファイル
const GeneralRole: React.FC<Props> = ({userId, userName}) => {
    const [detailLoaded, setDetailLoaded] = useState(false);
    const [detailData, setDetailData] = useState<PdIdsPdType | []>([]);
    const [usersData, setUsersData] = useState<UsersUserType | []>([]);

    useEffect(() => {
        const initUserGroupExec = async () => {
            const input_data = userId;
            const initUserGroupIdInput: PdIdsInput & UsersInput = {
                userGroupId: input_data,
            };

            try {
                const pdsResponse = await API.graphql(graphqlOperation(getPdIds, { input: initUserGroupIdInput }));
                const usersResponse = await API.graphql(graphqlOperation(getUsers, { input: initUserGroupIdInput }));
                const userList: any = {};
                // @ts-ignore
                usersResponse.data.getUsers.data.users.forEach((user) => { userList[user.id] = user });
                // @ts-ignore
                setDetailData(pdsResponse.data.getPdIds.data.pds);
                setUsersData(userList);
                setDetailLoaded(true);
            } catch (e) {
                console.log(e);
            }
        };
        initUserGroupExec();
    }, []);

    if (detailLoaded) {
        const pdUserMapping: any = {};
        detailData.forEach(pd =>  pdUserMapping[pd.id] = pd.userId );

        const pds = [];
        pds.push({ value: 'All', label: 'All' })
        // Pds
        detailData.forEach((data) => {
            pds.push(
                { value: data.id, label: `${data.identifier}(${usersData[data.userId]?.name || "-"})` }
            );
        });

        return(
            <SetTabAndTab allPds={pds} userList={usersData} pdUserMap={pdUserMapping} userId={userId} userName={userName}></SetTabAndTab>
        );
    } else {
        return (
            <DefaultMap></DefaultMap>
        );
    }
};

export default GeneralRole;
