import * as React from 'react';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import TabActives from "./tabs/TabActives";
import TabStatics from "./tabs/TabStatics";
import TabInfo from "./tabs/TabInfo";
import {useRecoilState, useSetRecoilState} from "recoil";
import {viaTypeState} from "../../../grobalStates/atoms/viaTypeAtom";
import {pdsListState} from "../../../grobalStates/atoms/pdsListAtom";
import {movieTypeState} from "../../../grobalStates/atoms/movieTypeAtom";
import {notificationKindState} from "../../../grobalStates/atoms/notificationKindAtom";
import {timeState} from "../../../grobalStates/atoms/timeAtom";
import {calendarFromState} from "../../../grobalStates/atoms/calendarFromAtom";
import {tabChangeState} from "../../../grobalStates/atoms/tabChangeAtom";


interface TabPanelProps {
    children?: React.ReactNode;
    dir?: string;
    index: number;
    value: number;
}
// ログインのデータを受け取る
type Props = {
    state_data: any;
    tab_name: any;
    tab_value: number;
    group_selected: any;
    all_groups: any;
};

const tabStyle = (flag: boolean) => {
    if (flag) {
        return {
            // textTransformはここで指定すると何故か型エラーとなるため後で追加
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "18px",
            backgroundColor: '#50687e',
            opacity: 1,
            borderTop: '1px solid #a8b4c0',
            borderLeft: '1px solid #a8b4c0',
            borderRight: '1px solid #a8b4c0',
            borderRadius: "10px 10px 0 0",
            letterSpacing: "2px",
        }
    } else {
        return {
            fontFamily: "Poppins",
            fontWeight: 600,
            fontSize: "18px",
            backgroundColor: '#5f7d95',
            opacity: 1,
            borderTop: '1px solid #a8b4c0',
            borderLeft: '1px solid #a8b4c0',
            borderRight: '1px solid #a8b4c0',
            borderBottom: '1px solid #a8b4c0',
            borderRadius: "10px 10px 0 0",
            letterSpacing: "2px",
        }
    }
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography component="div"
                                style={{
                                    fontFamily: "Poppins",
                                    fontWeight: 600,
                                    paddingTop: 20,
                                }}>
                        {children}
                    </Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const TabChanges: React.FC<Props> = ( { state_data, tab_name, tab_value,
                                                 group_selected, all_groups }) => {
    //管理者権限のタブ切替部分
    const [viaType, setViaChange] = useRecoilState(viaTypeState);

    // 以下、タブ切り替えに利用
    const [tabChange, setTabChange] = useRecoilState(tabChangeState);

    // 以下、初期化の更新に利用
    const setPdDefault = useSetRecoilState(pdsListState);
    const setMovieTypeDefault = useSetRecoilState(movieTypeState);
    const setNotificationDefault = useSetRecoilState(notificationKindState);
    const setTimeDefault = useSetRecoilState(timeState);
    const setCalendarFromDefault = useSetRecoilState(calendarFromState);

    if (viaType == 'byMap'){
        // マップ経由による操作
        // Infoタブのvalue(2)にする
        setTabChange(2);
    }


    const theme = useTheme();

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        //タブ操作系のステート各種初期化変更
        setViaChange('byTab');
        // PDの選択肢を初期化する
        setPdDefault({value: 'All', label: 'All'});
        // MovieTypeの選択肢を初期化する
        setMovieTypeDefault('Live');
        // カレンダーのFromの値を初期化(当日)にする
        setCalendarFromDefault(new Date());
        // AlertとCallsの選択肢を初期化する
        setNotificationDefault({value: 1, label: 'Alert'});
        // Timeの選択肢を初期化する
        setTimeDefault({value: 'Time', label: 'Time'});

        // Tabの切り替え
        setTabChange(newValue);
    };

    return (
        <Box sx={{ bgcolor: 'background.paper' }}>
            <AppBar position="static" style={{ backgroundColor: '#5f7d95' }}>
                <Tabs
                    value={tabChange}
                    onChange={handleChange}
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: '0px'
                        }
                    }}
                >
                    <Tab label="Actives" {...a11yProps(0)} style={{...tabStyle(tabChange === 0), textTransform: "none"}} />
                    <Tab label="Statics" {...a11yProps(1)} style={{...tabStyle(tabChange === 1), textTransform: "none"}} />
                    <Tab label="Info" {...a11yProps(2)} style={{...tabStyle(tabChange === 2), textTransform: "none"}} />
                </Tabs>
            </AppBar>
            <SwipeableViews
                axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                index={tabChange}
                style={{ height: "calc(100vh - 190px)", overflowY: "auto", backgroundColor: '#50687e', borderLeft: '1px solid #a8b4c0', borderRight: '1px solid #a8b4c0', borderBottom: '1px solid #a8b4c0' }}
            >
                <TabPanel value={tabChange} index={0} dir={theme.direction}>
                    <TabActives state_data={state_data} tab_name={tab_name} all_groups={all_groups}/>
                </TabPanel>
                <TabPanel value={tabChange} index={1} dir={theme.direction}>
                    <TabStatics state_data={state_data} tab_name={tab_name} all_groups={all_groups}></TabStatics>
                </TabPanel>
                <TabPanel value={tabChange} index={2} dir={theme.direction}>
                    <TabInfo state_data={state_data} tab_name={tab_name} all_groups={all_groups}></TabInfo>
                </TabPanel>
            </SwipeableViews>
        </Box>
    );
};

export default TabChanges;
