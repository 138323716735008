import React from "react";
import {useRecoilValue} from "recoil";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import NoVideoBox from "./NoVideoBox";

const border_style = {
    backgroundColor: '#3f4d63',
    content: '""',
    borderRadius: '2px',
    display: 'block',
    height: '4px'
}

type Props = {
    message?: string;
    setRetry201?: any
};

const NoResultBoxLayouts: React.FC<Props> = ({message, setRetry201}) => {
    const type_record = useRecoilValue(movieTypeState);

    if (message === 'PDにストリーミング動画を要求中です...'){
        setRetry201(1);
    }

    return (
        <div style={{color: 'white', paddingTop: '10px'}}>
            <label style={{fontSize: '20px'}}>{type_record}</label>
            <NoVideoBox message={message}/>
            <div style={{marginTop: '40px'}}>
                <div style={{ padding: '3px 20px' }}>
                    <label style={{fontSize: '18px'}}>Area</label>
                    <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>No Data</label>
                </div>
                {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                <div style={border_style}></div>
                <div style={{ padding: '3px 20px' }}>
                    <label style={{fontSize: '18px'}}>Speed</label>
                    <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>No Data</label>
                </div>
                {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                <div style={border_style}></div>
                <div style={{ padding: '3px 20px' }}>
                    <label style={{fontSize: '18px'}}>Position</label>
                    <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>No Data</label>
                </div>
                {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                <div style={border_style}></div>
                <div style={{ padding: '3px 20px' }}>
                    <label style={{fontSize: '18px'}}>Time</label>
                    <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>No Data</label>
                </div>
                {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                <div style={border_style}></div>
            </div>
        </div>
    );
};

export default NoResultBoxLayouts;
