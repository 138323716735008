import React, {useEffect, useState} from 'react'
import {useRecoilValue} from "recoil";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import ReactPlayer from 'react-player'
import {
    streamingUrlInput,
    streamingUrlType,
} from "../../../../../API";

import {API, graphqlOperation} from "aws-amplify";
import {getStreamingUrl} from "../../../../../graphql/queries";
import DisplayLiveMovieInfoDetails from "./DisplayLiveMovieInfoDetails";
import NoResultBoxLayouts from "../../../common/tabs/tab_info/NoResultBoxLayouts";


type Props = {
    pd_value: number;
    state_data: any;
};

// Live動画を表示
const DisplayLiveMovieInfo: React.FC<Props> = ({pd_value, state_data}) => {
    // URLセット用
    const [movieUrlLoaded, setMovieUrlLoaded] = useState(false);
    const [urlData, setMovieUrlData] = useState<[streamingUrlType] | []>([]);
    // 結果が201の時
    const [retryGetUrl, setRetry201] = useState(0);

    const type_record = useRecoilValue(movieTypeState);

    let message = "";

    // getStreamingUrl ストリーミングURL取得
    const initUrlExec = async (pd_value: number | null) => {
        let initPdIdInput: streamingUrlInput | null = null;
        if (pd_value) initPdIdInput = { pdId: pd_value };
        try {
            const response = await API.graphql(graphqlOperation(getStreamingUrl, { input: initPdIdInput }));
            // @ts-ignore
            setMovieUrlData(response.data.getStreamingUrl);
            console.log('初回のみ流れています');
            setMovieUrlLoaded(true);
            setRetry201(0);
        } catch (e) {
            console.log(e);
        }
    };

    // Pdが変化したときに実行
    useEffect(() => {
        initUrlExec(pd_value);
    }, [pd_value]);

    // 201の時にuseEffectを走らせる(setIntervalは走り続けるのでtrue/falseで固定する)
     useEffect(() => {
         if (retryGetUrl !== 0){
             const interval = setInterval(() => {
                 console.log('201の間は、5秒毎に再リクエストを投げます');
                 initUrlExec(pd_value);
             }, 5000);
             return () => clearInterval(interval);
         }
    }, [retryGetUrl]);

    // 初回URL情報を取得
    if (movieUrlLoaded){
        const url_object = Object.values(urlData).map(url_data => url_data)
        const url_status_code = url_object[0] as unknown as string;
        // URLステータス(正常)
        if (url_status_code == '200'){
            const url_data = url_object[1] as unknown as object;
            const arrange_url = Object.values(url_data)
            const url = arrange_url[0];
            return (
                <div style={{color: 'white', paddingTop: '10px'}}>
                    <label style={{fontSize: '20px'}}>{type_record}</label>
                    <ReactPlayer
                        url={url}
                        playing={true}
                        controls={true}
                        width={'450px'}
                        height={'260px'}
                    />
                    <DisplayLiveMovieInfoDetails state_data={state_data} pd_value={pd_value}/>
                </div>
            );
            //# 201: 未配信(配信stream未作成 or 配信開始前) dataはnullで返却(リクエストを叩く)
        } else if (url_status_code == '201') {
            message = "PDにストリーミング動画を要求中です..."
            //# 300: PD取得エラー(pdIdのDBが存在しない or 権限がない)
            //# 301: PD非アクティブエラー(PDが非アクティブ状態)
        } else {
            message = "配信条件を満たしていませんので、ストリーミング動画は表示できません。"
        }
        // ローディング中
    } else {
        message = "loading..."
    }
    return <NoResultBoxLayouts message={message} setRetry201={setRetry201}/>
};

export default DisplayLiveMovieInfo;
