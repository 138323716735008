import React from "react";
import {TimeSelect} from "../../../../../form/info/time_select";
import ResultTimeBox from "./ResultTimeBox";

type Props = {
    search_result: any;
    end_day: any;
    disable_type: any;
};

const PreCheckResultTimeBox: React.FC<Props> = ({ search_result, end_day, disable_type }) => {
    // Recordedの場合
    if (!disable_type){
        return (
            <ResultTimeBox search_result={search_result}
                           end_day={ end_day }
                           disable_type={disable_type} />
        );
    } else {
        const selectOption = [{value: 'Time', label: 'Time'}];
        return (
            <div style={{ marginTop: "10px" }}>
                <TimeSelect disable_type={disable_type} option={selectOption}
                            resultInfo={null} search_result={search_result}/>
            </div>
        );
    }
};

export default PreCheckResultTimeBox;
