import React from "react";
import Map from "../../../../pages/Map";
import SetStaticsMap from "./SetStaticsMap";

type Props = {
    object: any;
};

const DisplayStaticsMap: React.FC<Props> = ({ object }) => {
    if (object){
        const travelData = object[0];
        const alertData = object[1];
        const callData = object[2];

        return (
            <SetStaticsMap travel={travelData} alert={alertData} call={callData}/>
        );
    } else {
        return (
            <Map/>
        );
    }
};


export default DisplayStaticsMap;
