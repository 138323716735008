import React, { useState } from "react";
import GenericTemplate from "../templates/GenericTemplate";
import DefaultMap from "../templates/common/maps/DefaultMap";
import FirstView from "./FirstView";

const HomePage: React.FC = () => {
    // ログインデータ取得
    const [loginData, setLoginInfo] = useState(false)
    if (loginData) {
        return (
            <GenericTemplate title="" login_user={setLoginInfo}>
                <FirstView loginObject={loginData}></FirstView>
            </GenericTemplate>
        );
        // 初回アクセス
    } else {
        return (
            <GenericTemplate title="" login_user={setLoginInfo}>
                <DefaultMap></DefaultMap>
            </GenericTemplate>
        );
    }
};

export default HomePage;
