import React, { Component } from 'react'
import ReactPlayer from 'react-player'

type Props = {
    url: any;
};

// 動画を表示
export const DisplayMovie: React.FC<Props> = ({url}) => {
    return (
        <div>
            <ReactPlayer
                url={url}
                playing={true}
                controls={true}
                width={'450px'}
                height={'260px'}
            />
        </div>
    );
};
