import React from 'react';

// Defaultのタブ
const DefaultTab: React.FC = () => {
    return (
        <div style={{ color: 'white', height: "300px" }}>
            <p>loading...</p>
        </div>
    );
};

export default DefaultTab;
