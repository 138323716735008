import React from "react";
import { GoogleMap, LoadScript } from "@react-google-maps/api";
import { MapStyles } from "../templates/MapStyles";

const containerStyle = {
    height: "calc(100vh - 120px)",
    width: "100%",
};

const center = {
    lat: 35.709984139,
    lng: 139.810703,
};

const options = {
    styles: MapStyles,
    streetViewControl: false,
    mapTypeControl: true,
    mapTypeControlOptions: {
        mapTypeIds: ["roadmap", "hybrid"],
    },
    minZoom: 2,
};

const Map: React.FC = () => {
    return (
        <div title="MAP">
            <LoadScript googleMapsApiKey="AIzaSyBvoOPsAtWCorUALlSKopruip_tE75Bcic&language=en">
                <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={5} options={options}></GoogleMap>
            </LoadScript>
        </div>
    );
};

export default Map;
