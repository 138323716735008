import React, {useState} from "react";
import DisplayInfoMap from "../../common/maps/info/DisplayInfoMap";
import TabChanges from "../TabChanges";

type Props = {
    object: any;
    user: any;
    allPds: any;
};

const InfoMap: React.FC<Props> = ({ object, user, allPds }) => {
    // Info地図描画データ
    const [infoMapData, setInfoData] = useState(false);

    return (
        <div style={{ overflow: 'hidden' }}>
            <div style={{ width: 'calc(100vw - 530px)', float: 'left', marginLeft: '0', marginRight: '0' }}>
                <DisplayInfoMap object={ infoMapData } />
            </div>
            <div style={{ width: '500px', float: 'right', marginTop: '10px', marginRight: '10px' }}>
                <TabChanges state_data={ setInfoData }
                            tab_name={ object }
                            tab_value={ 2 }
                            user={user}
                            all_pds={allPds}></TabChanges>
            </div>
        </div>
    );
};

export default InfoMap;
