import React, {useEffect, useState} from 'react'
import {usePrevious} from "react-use";
import {streamingInfoInput, streamingInfoType} from "../../../../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getStreamingInfo} from "../../../../../graphql/queries";
import IdentifyingAddress from "../../../common/tabs/tab_info/IdentifyingAddress";
import NoResultBoxLayouts from "../../../common/tabs/tab_info/NoResultBoxLayouts";

type Props = {
    pd_value: number;
    state_data: any;
};

const border_style = {
    backgroundColor: '#3f4d63',
    content: '""',
    borderRadius: '2px',
    display: 'block',
    height: '4px'
}

// Live動画を表示
const DisplayLiveMovieInfoDetails: React.FC<Props> = ({pd_value, state_data}) => {
    // Infoセット用
    const [movieInfoLoaded, setMovieInfoLoaded] = useState(false);
    const [infoData, setMovieInfoData] = useState<[streamingInfoType] | []>([]);
    const old_info_data = usePrevious(infoData);
    // 初回に送信するため
    const [firstTime, setFirst] = useState(true);
    // 1sで送信するため
    const [each1Time, setEachSeconds] = useState(true);

    const streamingCountMax = 10800; // ストーリミング最大継続時間(秒)

    let streamingCount = 0; // ストーリミングタイマー用

    // getStreamingInfo ストリーミング情報取得初回
    const initUserGroupExec = async (pd_value: number | null) => {
        let initPdIdInput: streamingInfoInput | null = null;
        if (pd_value) initPdIdInput = { pdId: pd_value };
        if ( streamingCount < streamingCountMax ) {
            console.log('投げました count=' + streamingCount );
            try {
                const response = await API.graphql(graphqlOperation(getStreamingInfo, { input: initPdIdInput }));
                // @ts-ignore
                setMovieInfoData(response.data.getStreamingInfo);
                setMovieInfoLoaded(true);
            } catch (e) {
                console.log(e);
            }
        } else {
            console.log('投げません count=' + streamingCount );
        }
    };

    let message="";

    // setInterval用のカウント1秒経過でuseEffectを走らせる
    useEffect(() => {
        streamingCount = 0; // ストーリミングタイマー用
        const interval = setInterval(() => {
            streamingCount ++; // ストーリミングタイマー用
            console.log('1秒経過GetStreamingInfoのリクエストを投げます');
            initUserGroupExec(pd_value);
            setEachSeconds(true)
        }, 1000);
        return () => clearInterval(interval);
    }, [pd_value]);

    if (movieInfoLoaded){
        const info_object = Object.values(infoData).map(info_data => info_data)
        const info_status_code = info_object[0] as unknown as string;
        // 地図に送る処理
        const current_pds =[];
        if (info_status_code　== '200'){
            const object_data = Object.values(info_object).map(data => data)
            const info_data_hash = object_data[1];
            const info_data = Object.values(info_data_hash).map(data => data)
            const lat = info_data[0];
            const long = info_data[1];
            const geo_lat = info_data[0] as number;
            const geo_long = info_data[1] as number;
            const speed = info_data[2];
            const time = info_data[3];
            const iconKind = info_data[4];
            const direction = info_data[5];

            if (firstTime) {
                // 初回実行時
                console.log('初回データ受け取ったぞ'+long+","+lat+",t="+time+"s="+speed);
                current_pds.push(
                    {lat: lat, lng: long, iconType: 'live', iconKind: iconKind, direction: direction}
                );
                state_data(current_pds);
                setFirst(false);
            } else if (each1Time && (old_info_data !== infoData)){
                // 10秒経過時、古いデータと取得データに変化のあった時
                console.log('更新データ受け取ったぞ'+long+","+lat+",t="+time+"s="+speed);
                current_pds.push(
                    {lat: lat, lng: long, iconType: 'live', iconKind: iconKind, direction: direction}
                );
                state_data(current_pds);
                setEachSeconds(false);
            }

            return (
                <div style={{marginTop: '40px'}}>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Area</label>
                        <IdentifyingAddress lat={geo_lat} lng={geo_long}/>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Speed</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{speed}km/h</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Position</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{lat} / {long}</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                    <div style={{ padding: '3px 20px' }}>
                        <label style={{fontSize: '18px'}}>Time</label>
                        <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{time}</label>
                    </div>
                    {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
                    <div style={border_style}></div>
                </div>
            );
            //  # 300: PD取得エラー(pdIdのDBが存在しない or 権限がない)
        } else if(info_status_code　== '300'){
            message = "PDが存在しません。";
            //  # 301: PD非アクティブエラー(PDが非アクティブ状態)
        } else if(info_status_code　== '301'){
            message = "PDが非アクティブ状態です。";
            //  # 302: 走行データ取得エラー(pdDataLogを取得できない)
        } else {
            message = "走行データの取得エラーです。";
        }
    } else {
        message = "loading...";
    }

    return (
        <div style={{marginTop: '40px'}}>
            <div style={{ padding: '3px 20px' }}>
                <label style={{fontSize: '18px'}}>Area</label>
                <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{message}</label>
            </div>
            {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
            <div style={border_style}></div>
            <div style={{ padding: '3px 20px' }}>
                <label style={{fontSize: '18px'}}>Speed</label>
                <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{message}</label>
            </div>
            {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
            <div style={border_style}></div>
            <div style={{ padding: '3px 20px' }}>
                <label style={{fontSize: '18px'}}>Position</label>
                <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{message}</label>
            </div>
            {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
            <div style={border_style}></div>
            <div style={{ padding: '3px 20px' }}>
                <label style={{fontSize: '18px'}}>Time</label>
                <label style={{fontSize: '18px', fontWeight: 300, float: 'right'}}>{message}</label>
            </div>
            {/* ReactではCSS疑似要素(Pseudo-elementsのAfter)が使えない?ので以下挿入 */}
            <div style={border_style}></div>
        </div>
    )
}

export default DisplayLiveMovieInfoDetails;
