import React, {useEffect, useState} from "react";
import {UserGroupsUserGroupType, PdIdsPdType, UsersUserType} from "../../API";
import {API, graphqlOperation} from "aws-amplify";
import {getUserGroups, getPdIds, getUsers} from "../../graphql/queries";
import MapAndTabChange from "../templates/admin_role/MapAndTabChange";
import DefaultMap from "../templates/common/maps/DefaultMap";

// ログインユーザーグループが管理者の時の親ファイル
const AdminRole: React.FC = () => {
    // 取得状況
    const [groupsLoaded, setGroupLoaded] = useState(false);
    // 全てのグループ情報
    const [groupData, setAllUserGroupData] = useState<[UserGroupsUserGroupType] | []>([]);
    // 全てのpd情報
    const [pdsData, setPdsData] = useState<PdIdsPdType | []>([]);
    // 全てのuser情報
    const [usersData, setUsersData] = useState<UsersUserType | []>([]);

    useEffect(() => {
        const allUserGroupsExec = async () => {
            try {
                const response = await API.graphql(graphqlOperation(getUserGroups));
                // @ts-ignore
                setAllUserGroupData(response.data.getUserGroups.data.userGroups);

                const pdsResponse = await API.graphql(graphqlOperation(getPdIds));
                const usersResponse = await API.graphql(graphqlOperation(getUsers));
                const userList: any = {};
                // @ts-ignore
                usersResponse.data.getUsers.data.users.forEach((user) => { userList[user.id] = user });
                // @ts-ignore
                setPdsData(pdsResponse.data.getPdIds.data.pds);
                setUsersData(userList);
                setGroupLoaded(true);
            } catch (e) {
                console.log(e);
            }
        };
        allUserGroupsExec();
    }, []);

    // グループが取得されたかの確認
    if (groupsLoaded) {
        // 初期値のAllを追加する
        const allGroup = []
        allGroup.push(
            {value: 'All', label: 'All'}
        );
        // 全てのGroup情報
        const gotAllGroup = Object.values(groupData).map(gotData => gotData)
        for (let i = 0; i < gotAllGroup.length; i++){
            const data = gotAllGroup[i] as object;
            const resultData = Object.values(data);
            const id = resultData[0] as string;
            const name = resultData[1] as string;
            allGroup.push(
                {value: id, label: name}
            );
        }

        const pdUserMapping: any = {};
        pdsData.forEach(pd =>  pdUserMapping[pd.id] = pd.userId );

        return(
            <MapAndTabChange allGroup={allGroup} userList={usersData} pdUserMap={pdUserMapping}></MapAndTabChange>
        );
    } else {
        return (
            <DefaultMap></DefaultMap>
        );
    }
};

export default AdminRole;
