import React, {useEffect, useState} from "react";
import {GroupSingleSelect} from "../../../../../form/admin/info/group_single_select";
import {PDIdSelect} from "../../../../../form/info/pd_id_select";
import {StartDaySelect} from "../../../../../form/info/start_day_select";
import {EndDaySelect} from "../../../../../form/info/end_day_select";
import {RadioButton} from "../../../../../form/info/radio_button";
import {AlertTypeSelect} from "../../../../../form/info/alert_type_select";
import {useRecoilValue} from "recoil";
import {movieTypeState} from "../../../../../grobalStates/atoms/movieTypeAtom";
import PreCheckResultTimeBox from "../../../common/tabs/tab_info/PreCheckResultTimeBox";
import {timeState} from "../../../../../grobalStates/atoms/timeAtom";

// selectable(全グループのリスト)
// group_value(ステートを変更する際に利用)
// search_result(timeの選択肢用)
// pds(全PDのリスト)
type Props = {
    selectable: any;
    search_result: any;
    pds: any;
};

const SearchCondition: React.FC<Props> = ({selectable, search_result, pds }) => {
    // recoilでLive/Recordedの情報を取得
    const movieData = useRecoilValue(movieTypeState);

    // Map経由では、録画動画にする
    const fromMapTime = useRecoilValue(timeState);
    // ラジオボタンの値が変化したときに実行
    const [disableData, setDisableData] = useState(false);
    useEffect(() => {
        setDisableData(!disableData);
        if (fromMapTime.label === "地図Alert" || fromMapTime.label === "地図Call") {
            setDisableData(false);
        }
    }, [movieData]);
    ////  日付の終了日
    const [endDayData, setEndDayData] = useState(new Date());

    return (
        <div>
            <div style={{ backgroundColor: '#50687e', paddingBottom: '30px' }}>
                <div>
                    <div>
                        <div style={{ display: "flex" }}>
                            {/* Group */}
                            <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Group</label>
                            <GroupSingleSelect group={ selectable }/>
                        </div>
                        <div style={{ display: "flex", marginTop: '8px' }}>
                            {/* ID */}
                            <label htmlFor="search-keyword"style={{ width: "90px", fontSize: "20px", color: 'white' }}>User</label>
                            <PDIdSelect selection={ pds }/>
                        </div>
                        <div style={{ display: "flex" }}>
                            {/* RadioButton */}
                            <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Type</label>
                            <RadioButton/>
                        </div>
                        <div style={{ marginTop: '11px', height: '40px'}}>
                            {/* カレンダー */}
                            <div style={{ display: "flex" }}>
                                <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Date</label>
                                <div style={{width: '360px', float: 'right'}}>
                                    <div style={{width: '43%', float: 'left'}}>
                                        <StartDaySelect disable_type={disableData} />
                                    </div>
                                    <label style={{marginLeft: '14px', paddingTop: '4px', color: "white", fontSize: "20px"}}>〜</label>
                                    <div style={{width: '43%',float: 'right'}}>
                                        <EndDaySelect value_info={ setEndDayData } disable_type={disableData} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ height: '40px'}}>
                            {/* セレクト */}
                            <div style={{ display: "flex" }}>
                                <label htmlFor="search-keyword" style={{ width: "90px", fontSize: "20px", color: 'white' }}>Select</label>
                                <div>
                                    <div>
                                        <AlertTypeSelect disable_type={disableData} />
                                    </div>
                                    <PreCheckResultTimeBox search_result={search_result}
                                                           end_day={ endDayData }
                                                           disable_type={disableData} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default SearchCondition;
