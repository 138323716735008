import React, { Fragment } from 'react';
import Select from 'react-select';
import {SelectStyles} from "../../SelectStyles";

type SingleProps = {
    user: any;
};

export const SingleSelect: React.FC<SingleProps> = ( { user }) => {
    const dispaySelect = [
        user
    ]

    return (
        <Fragment>
            <Select
                 styles={SelectStyles}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={dispaySelect[0]}
                isDisabled={true}
                name="color"
                options={dispaySelect}
            />
        </Fragment>
    );
}
