import React from "react";
// userRoleが管理者
import AdminRole from "./AdminRole";
// userRoleが一般
import GeneralRole from "./GeneralRole";

type Props = {
    loginObject: any;
};

// ログイン後のFirstView
const FirstView: React.FC<Props> = ({ loginObject }) => {
    // グループID
    const userId = loginObject[0];
    const inputUserId = Number(userId);
    // グループ名
    const userName = loginObject[1];
    const displayUserName = String(userName);
    // グループロール
    const userRole = loginObject[2];

    // ロールが管理者の場合
    if (userRole === 0){
        return (
            <AdminRole></AdminRole>
        );
        // ロールが一般の場合
    } else {
        return (
            <GeneralRole userId={inputUserId} userName={displayUserName}></GeneralRole>
        );
    }
};

export default FirstView;
