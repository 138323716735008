import React, { useState, useEffect } from 'react';
import {useRecoilValue} from "recoil";
import TracingGraph from "../../../components/templates/general_role/tabs/tab_statics/graphs/TracingGraph";
import AlertsGraph from "../../../components/templates/general_role/tabs/tab_statics/graphs/AlertsGraph";
import EmergencyCallsGraph from "../../../components/templates/general_role/tabs/tab_statics/graphs/EmergencyCallsGraph";
import {pdsListState} from "../../../grobalStates/atoms/pdsListAtom";
import {calendarFromState} from "../../../grobalStates/atoms/calendarFromAtom";
import {API, graphqlOperation} from "aws-amplify";
import {
    StatisticsTabInput, StatisticsTabType,
    statisticsLatlongListsInput, statisticsLatlongListsType,
    statisticsAlertsInput, statisticsAlertsType
} from "../../../API";
import {
    getStatisticsTab,
    getStatisticsLatlongLists,
    getStatisticsAlerts
} from "../../../graphql/queries";
import NoGraphDataLayouts from "../../../components/templates/common/tabs/tab_statics/NoGraphDataLayouts";
import { StyledCheckBoxText, StyledCheckBox } from "../../../form/StyledCheckBox";

type SingleProps = {
    group: any;
    endDay: any;
    state_data: any;
};

export const GeneralCheckBox: React.FC<SingleProps> = ({ group, endDay, state_data }) => {
    // タブ内表示用データ取得
    const [tabDataLoaded, setTabDataLoaded] = useState(false);
    const [tabData, setTabData] = useState<[StatisticsTabType] | []>([]);

    // 地図描画用データ取得
    //// statisticsLatlongListsType取得(ポリライン)
    const [travelMapOn, setTravelMapOn] = useState(0);
    const [statisticsTravelLoaded, setStatisticsTravelLoaded] = useState(0);
    const [statisticsTravelData, setStatisticsTravelData] = useState<[statisticsLatlongListsType] | []>([]);

    //// statisticsAlertsType取得(アラート)
    const [alertsMapOn, setAlertsMapOn] = useState(0);
    const [statisticsAlertLoaded, setStatisticsAlertLoaded] = useState(0);
    const [statisticsAlertData, setStatisticsAlertData] = useState<[statisticsAlertsType] | []>([]);

    //// statisticsAlertsType取得(コール)
    const [emergencyMapOn, setEmergencyMapOn] = useState(0);
    const [statisticsCallLoaded, setStatisticsCallLoaded] = useState(0);
    const [statisticsCallData, setStatisticsCallData] = useState<[statisticsAlertsType] | []>([]);

    // グループ(一般権限は固定)
    const targetGroup = Object.values(group);
    const groupValue = targetGroup[0];
    const inputGroupValue = Number(groupValue);

    // PD
    const pd = useRecoilValue(pdsListState);
    const targetPd = Object.values(pd);
    const pdValue = targetPd[0];
    const inputPdValue = Number(pdValue);

    // StartDay
    const targetStartDay = useRecoilValue(calendarFromState);
    const sy = targetStartDay.getFullYear();
    const sm = ("00" + (targetStartDay.getMonth()+1)).slice(-2);
    const sd = ("00" + targetStartDay.getDate()).slice(-2);
    const inputStartDay = sy + "/" + sm + "/" + sd;

    // EndDay
    const targetEndDay = endDay;
    const ey = targetEndDay.getFullYear();
    const em = ("00" + (targetEndDay.getMonth()+1)).slice(-2);
    const ed = ("00" + targetEndDay.getDate()).slice(-2);
    const inputEndDay = ey + "/" + em + "/" + ed;

    // getStatisticsTab取得
    const initStaticsTabExec = async (inputGroupValue: number | null, inputPdValue: number | null, inputStartDay: string, inputEndDay: string) => {
        if (Number.isNaN(inputPdValue)) inputPdValue = null as any;
        const statisticsTabInput: StatisticsTabInput = {
            userGroupId: inputGroupValue,
            pdId: inputPdValue,
            dateFrom: inputStartDay,
            dateTo: inputEndDay,
        };
        try {
            const response = await API.graphql(graphqlOperation(getStatisticsTab, { input: statisticsTabInput }));
            // @ts-ignore
            setTabData(response.data.getStatisticsTab);
            setTabDataLoaded(true);
        } catch (e) {
            console.log(e);
        }
    };

    // statisticsAlertsType取得(トラベル)
    const initTravelCheckedExec = async (inputGroupValue: number | null, inputPdValue: number | null, inputStartDay: string, inputEndDay: string) => {
        if (Number.isNaN(inputGroupValue)) inputGroupValue = null as any;
        if (Number.isNaN(inputPdValue)) inputPdValue = null as any;
        const statisticsTravelInput: statisticsLatlongListsInput = {
            userGroupId: inputGroupValue,
            pdId: inputPdValue,
            dateFrom: inputStartDay,
            dateTo: inputEndDay,
            northLatitude: null,
            eastLongitude: null,
            southLatitude: null,
            westLongitude: null,
        };
        try {
            const response = await API.graphql(graphqlOperation(getStatisticsLatlongLists, { input: statisticsTravelInput }));
            // @ts-ignore
            setStatisticsTravelData(response.data.getStatisticsLatlongLists);
            setStatisticsTravelLoaded(statisticsTravelLoaded + 1);
        } catch (e) {
            console.log(e);
        }
    };

    // statisticsAlertsType取得(アラート)
    const initAlertsCheckedExec = async (inputGroupValue: number | null, inputPdValue: number | null, inputStartDay: string, inputEndDay: string) => {
        if (Number.isNaN(inputGroupValue)) inputGroupValue = null as any;
        if (Number.isNaN(inputPdValue)) inputPdValue = null as any;
        const statisticsAlertsInput: statisticsAlertsInput = {
            userGroupId: inputGroupValue,
            pdId: inputPdValue,
            alertType: 1,
            dateFrom: inputStartDay,
            dateTo: inputEndDay,
        };
        try {
            const response = await API.graphql(graphqlOperation(getStatisticsAlerts, { input: statisticsAlertsInput }));
            // @ts-ignore
            setStatisticsAlertData(response.data.getStatisticsAlerts);
            setStatisticsAlertLoaded(statisticsAlertLoaded + 1);
        } catch (e) {
            console.log(e);
        }
    };

    // statisticsAlertsType取得(コール)
    const initCallsCheckedExec = async (inputGroupValue: number | null, inputPdValue: number | null, inputStartDay: string, inputEndDay: string) => {
        if (Number.isNaN(inputGroupValue)) inputGroupValue = null as any;
        if (Number.isNaN(inputPdValue)) inputPdValue = null as any;
        const statisticsCallsInput: statisticsAlertsInput = {
            userGroupId: inputGroupValue,
            pdId: inputPdValue,
            alertType: 2,
            dateFrom: inputStartDay,
            dateTo: inputEndDay,
        };
        try {
            const response = await API.graphql(graphqlOperation(getStatisticsAlerts, { input: statisticsCallsInput }));
            // @ts-ignore
            setStatisticsCallData(response.data.getStatisticsAlerts);
            setStatisticsCallLoaded(statisticsCallLoaded + 1);
        } catch (e) {
            console.log(e);
        }
    };

    // Travelsチェックボックス
    const [travelChecked, setTravelChecked] = useState(false);
    const handleOnTravelChange = () => {
        setTravelChecked(!travelChecked);
    };

    // Alertsチェックボックス
    const [alertsChecked, setAlertsChecked] = useState(false);
    const handleOnAlertsChange = () => {
        setAlertsChecked(!alertsChecked);
    };
    // Callsチェックボックス
    const [emergencyChecked, setEmergencyChecked] = useState(false);
    const handleOnEmergencyChange = () => {
        setEmergencyChecked(!emergencyChecked);
    };

    // 検索条件が変化した時
    useEffect(() => {
        initStaticsTabExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        if (travelChecked){
            initTravelCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsTravelLoaded(0);
        }
        if (alertsChecked){
            initAlertsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsAlertLoaded(0);
        }
        if (emergencyChecked){
            initCallsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsCallLoaded(0);
        }
    }, [inputGroupValue, inputPdValue, inputStartDay, inputEndDay]);

    // setInterval用のカウント10秒経過でuseEffectを走らせる
    useEffect(() => {
        const interval = setInterval(() => {
            console.log('10秒経過: Statics情報取得の再リクエストを実行');
            initStaticsTabExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
            if (travelChecked){
                initTravelCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
            } else {
                setStatisticsTravelLoaded(0);
            }
            if (alertsChecked){
                initAlertsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
            } else {
                setStatisticsAlertLoaded(0);
            }
            if (emergencyChecked){
                initCallsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
            } else {
                setStatisticsCallLoaded(0);
            }
        }, 10000);
        return () => clearInterval(interval);
    }, [inputGroupValue, inputPdValue, inputStartDay, inputEndDay, travelChecked, alertsChecked, emergencyChecked]);

    // チェックボックスが変化した時
    useEffect(() => {
        if (travelChecked){
            initTravelCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsTravelLoaded(0);
        }
        if (alertsChecked){
            initAlertsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsAlertLoaded(0);
        }
        if (emergencyChecked){
            initCallsCheckedExec(inputGroupValue, inputPdValue, inputStartDay, inputEndDay);
        } else {
            setStatisticsCallLoaded(0);
        }
    }, [travelChecked, alertsChecked, emergencyChecked]);

    // 取得結果が変化した時
    useEffect(() => {
        if (statisticsTravelLoaded !== 0){
            setTravelMapOn(travelMapOn + 1);
        } else {
            setTravelMapOn(0);
        }
        if (statisticsAlertLoaded !== 0){
            setAlertsMapOn(alertsMapOn + 1);
        } else {
            setAlertsMapOn(0);
        }
        if (statisticsCallLoaded !== 0){
            setEmergencyMapOn(emergencyMapOn + 1);
        } else {
            setEmergencyMapOn(0);
        }
    }, [statisticsTravelLoaded, statisticsAlertLoaded, statisticsCallLoaded]);

    // 地図描画判定が変化した時
    useEffect(() => {
        let forMap = [];
        if (travelMapOn !== 0){
            const travelData = Object.values(statisticsTravelData);
            const travelContents = travelData[1];
            let forTravelMap = [
                {displayType: 'travel', displayFlag: true, data: travelContents}
            ];
            forMap.push(forTravelMap);
        } else {
            let forTravelMap = [
                {displayType: 'travel', displayFlag: false, data: null}
            ];
            forMap.push(forTravelMap);
        }
        if (alertsMapOn !== 0){
            const alertData = Object.values(statisticsAlertData);
            const alertContents = alertData[1];
            let forAlertMap = [
                {displayType: 'alert', displayFlag: true, data: alertContents}
            ];
            forMap.push(forAlertMap);
        } else {
            let forAlertMap = [
                {displayType: 'alert', displayFlag: false, data: null}
            ];
            forMap.push(forAlertMap);
        }
        if (emergencyMapOn !== 0){
            const callData = Object.values(statisticsCallData);
            const callContents = callData[1];
            let forCallMap = [
                {displayType: 'call', displayFlag: true, data: callContents}
            ];
            forMap.push(forCallMap);
        } else {
            let forCallMap = [
                {displayType: 'call', displayFlag: false, data: null}
            ];
            forMap.push(forCallMap);
        }
        // 地図へ送信
        state_data(forMap)
    }, [travelMapOn, alertsMapOn, emergencyMapOn]);

    if (tabDataLoaded){
        const resultData = Object.values(tabData);
        const tabResultData = resultData[1];
        const tabDetailData = Object.values(tabResultData);
        const travel = tabDetailData[0];
        const alerts = tabDetailData[1];
        const calls = tabDetailData[2];
        const graphs = tabDetailData[3];
        const graphsDetailData = Object.values(graphs);
        const travelGraphs = graphsDetailData[0];
        const alertsGraphs = graphsDetailData[1];
        const callsGraphs = graphsDetailData[2];

        return (
            <div style={{ padding: '10px 10px 10px 10px', color: "white", fontSize: "20px" }}>
                {/* Travels */}
                <div style={{marginBottom: '10px'}}>
                    <div style={{marginBottom: '10px'}}>
                        <label htmlFor="Travels">
                            <div style={{float: 'left', marginBottom: '10px'}}>
                                <StyledCheckBox
                                    type="checkbox"
                                    id="Travels"
                                    name="Travels"
                                    value="Travels"
                                    checked={travelChecked}
                                    onChange={handleOnTravelChange}
                                />
                                <StyledCheckBoxText>Travels</StyledCheckBoxText>
                            </div>
                        </label>
                        <div style={{float: 'right', fontWeight: 500}}>
                            <label style={{ marginRight: '20px'}}>Total</label>
                            <label style={{ marginRight: '10px'}}>{travel}</label>
                        </div>
                    </div>
                    <TracingGraph result={travelGraphs}/>
                </div>
                {/* Alerts */}
                <div style={{marginBottom: '10px'}}>
                    <div>
                        <label htmlFor="Alerts">
                            <div style={{float: 'left', marginBottom: '10px'}}>
                                <StyledCheckBox
                                    type="checkbox"
                                    id="Alerts"
                                    name="Alerts"
                                    value="Alerts"
                                    checked={alertsChecked}
                                    onChange={handleOnAlertsChange}
                                />
                                <StyledCheckBoxText>Alerts</StyledCheckBoxText>
                            </div>
                        </label>
                        <div style={{float: 'right', fontWeight: 500}}>
                            <label style={{ marginRight: '20px'}}>Total</label>
                            <label style={{ marginRight: '10px'}}>{alerts}</label>
                        </div>
                    </div>
                    <AlertsGraph result={alertsGraphs}/>
                </div>
                {/* Calls */}
                <div>
                    <div>
                        <label htmlFor="Calls">
                            <div style={{float: 'left', marginBottom: '10px'}}>
                                <StyledCheckBox
                                    type="checkbox"
                                    id="Calls"
                                    name="Calls"
                                    value="Calls"
                                    checked={emergencyChecked}
                                    onChange={handleOnEmergencyChange}
                                />
                                <StyledCheckBoxText>Calls</StyledCheckBoxText>
                            </div>
                        </label>
                        <div style={{float: 'right', fontWeight: 500}}>
                            <label style={{ marginRight: '20px'}}>Total</label>
                            <label style={{ marginRight: '10px'}}>{calls}</label>
                        </div>
                    </div>
                    <EmergencyCallsGraph result={callsGraphs}/>
                </div>
            </div>
        );
    } else {
        return (
            <NoGraphDataLayouts />
        );
    }
}
